import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";

import {
  useBuscarResposta,
  useResponderQuestao,
} from "../../../queries/respostas";

import "./passos.css";

export const AulasFichamento = ({ questoes, status }) => {
  const questao = questoes && questoes.length > 0 ? questoes[0] : null;

  const buscarResposta = useBuscarResposta(questao?.passo, questao?._id);
  const { mutate, isLoading, isSuccess } = useResponderQuestao(
    questao?.passo,
    questao?._id
  );

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  useEffect(() => {
    if (isSuccess) buscarResposta.refetch();
  }, [buscarResposta, isSuccess]);

  if (status === "encerrado") {
    return (
      <>
        <h2>Prazo encerrado!</h2>
        <p style={{ fontSize: 14 }}>Essa atividade não está mais disponível.</p>
      </>
    );
  }
  if (status === "a iniciar") {
    return (
      <>
        <h2>Essa tarefa ainda não pode ser feita</h2>
        <p style={{ fontSize: 14 }}>
          Aguarde a data de liberação para poder fazer.
        </p>
      </>
    );
  }

  if (!questoes || questoes.length === 0) return null;

  if (buscarResposta.isLoading) {
    return (
      <ClipLoader color={"#dd2626"} loading="true" height={15} margin={8} />
    );
  }

  const responder = (conteudo, tipo_resposta, mime_type) => {
    mutate({ conteudo, tipo_resposta, mime_type });
  };

  const temResposta =
    isSuccess ||
    (buscarResposta?.data?.data && buscarResposta.data.data.length > 0);

  // TODO: Implementar múltiplas questões
  return (
    <form
      onSubmit={handleSubmit((formData) =>
        responder(formData.conteudo, questao.tipo_questao, questao.mime_type)
      )}
      className="passo-questoes-form"
    >
      <div className="passo-questao-container">
        {temResposta ? (
          <h3 className="tarefa-feita">Tarefa realizada!</h3>
        ) : null}
        <span className="passo-questao-enunciado">{questao.conteudo}</span>
        {temResposta ? (
          <div className="passo-questao-resposta-salva-container">
            <span className="passo-questao-sua-resposta">Sua resposta:</span>
            <p className="passo-questao-resposta-salva">
              {buscarResposta.data.data[0]?.conteudo}
            </p>
          </div>
        ) : (
          <textarea
            placeholder="Digite aqui sua resposta"
            width="100"
            rows="8"
            {...register("conteudo", {
              required: "A resposta não pode ser vazia",
            })}
            readOnly={!!temResposta}
          />
        )}
      </div>

      {isLoading ? (
        <ClipLoader color={"#dd2626"} loading="true" height={15} margin={8} />
      ) : temResposta ? (
        <div className="passo-questoes-btn">Salvo!</div>
      ) : (
        <button type="submit" className="passo-questoes-btn">
          Salvar
        </button>
      )}
    </form>
  );
};
