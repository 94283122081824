import PropTypes from "prop-types";
import "../styles/YoutubeEmbed.css";

const YoutubeEmbed = ({ width, height, embedId }) => (
  <iframe
    width={width}
    height={height}
    src={`https://www.youtube.com/embed/${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
