import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetCursoBySlug } from "../../queries/curso";
import { useBuscaInformacoesBrigadas } from "../../queries/matricula";
import { useUserQuery } from "../../queries/user";

import { HeaderAula, VisaoGeralCurso } from "./components";
import { AulasCursoControle } from "./AulasCursoControle";

import "../../styles/Aulas.css";

const AULAS_HARDCODED = [
  {
    titulo: "Apresentação do Curso",
    passos: [
      {
        tipo: "video",
        videoId: "fOQssSUwA60",
        status: "finalizado",
      },
      {
        tipo: "video",
        videoId: "r9ch-YC7Zds",
        status: "finalizado",
      },
    ],
  },
  {
    titulo: "Questão urbana: onde o trabalho de base acontece",
    subtitulo: "Bianca Tavolari",
    passos: [
      {
        tipo: "video",
        videoId: "cxiwH3OkzWA",
        status: "finalizado",
      },
      {
        tipo: "material",
        url: "https://drive.google.com/file/d/1HnsZfKmOOCSqy2nv-9obvF_7c6s576Tz/preview",
        formato: "pdf",
      },
      {
        tipo: "material",
        url: "https://drive.google.com/file/d/1W_a3ylZigx_qNzFnwzyR8LX2xffHffUT/preview",
        formato: "pdf",
      },
      {
        _id: "6552abdbb3fcb529ff78a7bf",
        tipo: "fichamento",
        status: "encerrado",
        questoes: [
          {
            _id: "65529ef12039a595b2073e5e",
            passo: "6552abdbb3fcb529ff78a7bf",
            conteudo:
              "Com base na Aula da Bianca Tavolari, conte pra gente como ocorreu o processo de gentrificação na sua cidade e relacione os conceitos de direito à cidade, apresentados por ela, com a luta do MTST.",
            tipo_questao: "texto",
            mime_type: "text/plain",
          },
        ],
      },
    ],
  },
  {
    titulo: "Questões de raça e gênero",
    subtitulo: "Juninho e Tabata",
    passos: [
      {
        tipo: "video",
        videoId: "EhiH89Qf70g",
        status: "finalizado",
      },
      {
        tipo: "material",
        url: "https://drive.google.com/file/d/1jrnqJU04iw4Hstmy7nmQa3YyICJqh6ke/preview",
        formato: "pdf",
      },
      {
        tipo: "material",
        url: "https://drive.google.com/file/d/16iOyB-ILv0ApI-7YCIlHluQAsBcK1LTf/preview",
        formato: "pdf",
      },
      {
        _id: "655d48185c26a3b843e28e53",
        tipo: "fichamento",
        status: "encerrado",
        questoes: [
          {
            _id: "655d48e45c26a3b843e28e54",
            passo: "655d48185c26a3b843e28e53",
            conteudo:
              "Com base nas falas do Juninho, Cláudinha e Thainá, na aula de domingo passado,  quais violências estruturais conseguimos identificar na nossa sociedade e como você, junto com o MTST pode contribuir para seu enfrentamento?",
            tipo_questao: "texto",
            mime_type: "text/plain",
          },
        ],
      },
    ],
  },
  {
    titulo: "Trabalho de Base e Organização Popular",
    subtitulo:
      "Dilemas na construção de força social partindo da luta cotidiana",
    passos: [
      {
        tipo: "conteudo",
        conteudo:
          "Se você faz parte dos grupos A ao I, sua aula será pelo google meet e o link será enviado no grupo. Se você faz parte dos grupos 1 ao 11, sua aula é presencial.",
      },
    ],
  },
  {
    titulo: "Religião e Religiosidade Popular",
    subtitulo: "Nancy Cardoso",
    passos: [
      {
        tipo: "video",
        videoId: "9kscP41-9rM",
        status: "finalizado",
      },
      {
        _id: "6570b5e5f07ae869ed07c702",
        tipo: "fichamento",
        status: "encerrado",
        questoes: [
          {
            _id: "6570b682f07ae869ed07c703",
            passo: "6570b5e5f07ae869ed07c702",
            conteudo:
              '🎥 Temos como indicação de material complementar o filme "Nada a Perder I", uma biografia de Edir Macedo, fundador da Igreja Universal do Reno de Deus. Disponível na Netflix ou no link: https://vimeo.com/679498209\n\n❓ A partir da reflexões propostas na aula da Prof. Nancy Cardoso que lições tiramos para atuar no nosso dia-a-dia de luta? E se considerarmos o filme "Nada a perder I", o que mais podemos acrescentar a essa reflexão?',
            tipo_questao: "texto",
            mime_type: "text/plain",
          },
        ],
      },
    ],
  },
  {
    titulo: "Relação entre luta específica e luta geral",
    subtitulo: "Como as pautas gerais fazem parte da luta pela moradia",
    passos: [
      {
        tipo: "video",
        videoId: "9kscP41-9rM",
        status: "finalizado",
      },
    ],
  },
  {
    titulo: "Cotidiano, Luta política e a organização do MTST",
    subtitulo:
      "Como construir força política e se organizar a partir do cotidiano",
    passos: [
      {
        tipo: "video",
        videoId: "o2WzERt-_cA",
      },
      {
        _id: "6586304e021ca9c8301d5875",
        tipo: "fichamento",
        status: "em andamento",
        questoes: [
          {
            _id: "658630fe021ca9c8301d5876",
            passo: "6586304e021ca9c8301d5875",
            conteudo:
              "Explique, em poucas palavras, o que significa militância para você.",
            tipo_questao: "texto",
            mime_type: "text/plain",
          },
        ],
      },
    ],
  },
  {
    titulo: "Lutas Populares na América Latina",
    subtitulo: "Com Rud Rafael",
    passos: [
      {
        tipo: "video",
        videoId: "-JB6DobbAgg",
      },
    ],
  },
];

export const Aulas = () => {
  const { slugCurso } = useParams();
  const { data } = useGetCursoBySlug(slugCurso);
  const { data: usuario } = useUserQuery();
  const curso = Array.isArray(data?.data) ? data?.data[0] : data?.data;
  const [tabSelecionada, setTabSelecionada] = useState("inicio");

  const { refetch } = useBuscaInformacoesBrigadas();

  useEffect(() => {
    refetch();
  }, [slugCurso]);

  const [aulaIndicada, setAulaIndicada] = useState(null);

  const aulas = AULAS_HARDCODED;
  // const aulas = curso?.aulas;

  const pegarAulaIndicada = (aula) => {
    console.log("no index", aula);
    setAulaIndicada(aula);
  };

  return (
    <div className="aulas-container">
      <HeaderAula titulo={curso?.titulo} />
      <div className="aulas-content">
        <div className="aulas-tab-nav">
          <TabNav
            id="inicio"
            tabSelecionada={tabSelecionada}
            setTabSelecionada={setTabSelecionada}
          >
            Início
          </TabNav>
          <TabNav
            id="curso"
            tabSelecionada={tabSelecionada}
            setTabSelecionada={setTabSelecionada}
          >
            Continuar
          </TabNav>
        </div>
        <div className="aulas-card">
          {tabSelecionada === "inicio" ? (
            <VisaoGeralCurso
              aulas={aulas}
              titulo={curso?.titulo}
              subtitulo={"Introdução ao trabalho de base"} //hardcoded
              onClickContinue={() => setTabSelecionada("curso")}
              aulaIndicada={pegarAulaIndicada}
            />
          ) : (
            // <AulasCursoControle aulas={curso.aulas} voltarAoInicio={() => setTabSelecionada("inicio")}/>
            <AulasCursoControle
              aulas={aulas}
              aulaCerta={aulaIndicada}
              voltarAoInicio={() => setTabSelecionada("inicio")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TabNav = ({ id, tabSelecionada, setTabSelecionada, children }) => {
  return (
    <a
      className={
        id === tabSelecionada ? "aulas-text-tab-ativo" : "aulas-text-tab"
      }
      onClick={() => setTabSelecionada(id)}
    >
      {children}
    </a>
  );
};
