import icCurso1 from "../../images/ic-etiqueta.png";
import icCurso2 from "../../images/ic-calendario.png";
import icCurso3 from "../../images/ic-livro.png";
import icCurso4 from "../../images/ic-relogio.png";

function formatarData(horarioISO) {
  const data = new Date(horarioISO);
  const dia = data.getUTCDate();
  const mes = data.getUTCMonth() + 1; // Adicionamos 1 porque os meses em JavaScript iniciam em zero.
  const ano = data.getUTCFullYear();

  const diaFormatado = dia < 10 ? `0${dia}` : dia;
  const mesFormatado = mes < 10 ? `0${mes}` : mes;

  return `${diaFormatado}/${mesFormatado}/${ano}`;
}

export const InformacoesGerais = ({ curso, children }) => {
  const { inscritos, data_inicio, aulas, categoria, formato } = curso;
  const dataInicio = data_inicio ? formatarData(data_inicio) : null;

  return (
    <>
      {inscritos ? (
        <span className="curso-section2-text-inscritos">
          {inscritos} inscritos
        </span>
      ) : null}
      <div className="curso-section2-wrapper-infos">
        <img
          src={icCurso1}
          alt="curso-section2-img-categ"
          className="curso-section2-img-categ"
        />
        <span className="curso-section2-text-tipo">Categoria</span>
        <span className="curso-section2-text-dado">{categoria}</span>
        <img
          src={icCurso2}
          alt="curso-section2-img-data"
          className="curso-section2-img-data"
        />
        <span className="curso-section2-text-tipo">Inicio</span>
        <span className="curso-section2-text-dado">{dataInicio}</span>
        <img
          src={icCurso3}
          alt="curso-section2-img-forma"
          className="curso-section2-img-forma"
        />
        <span className="curso-section2-text-tipo">Formato</span>
        <span className="curso-section2-text-dado">
          {formato ? formato : "Presencial e Online"}
        </span>
        <img
          src={icCurso4}
          alt="curso-section2-img-duracao"
          className="curso-section2-img-duracao"
        />
        <span className="curso-section2-text-tipo">Duração</span>
        <span className="curso-section2-text-dado">{aulas.length} aulas</span>
      </div>
      {formato.includes("*") ? (
        <span className="curso-section2-obs">
          * O formato está sujeito à organização do MTST no seu Estado
        </span>
      ) : null}
      {children}
    </>
  );
};
