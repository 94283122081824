import { Dropdown, Input } from "../../../../components";
import { VALIDATION_RULES_BY_FIELD } from "../../../../utils/formValidation";

const RedeSocialFormulario = ({
  redeSocial,
  redesSociais,
  setRedesSociais,
}) => {
  const opcoes_redes_sociais = [
    "Instagram",
    "Facebook",
    "Twitter",
    "Twitch TV",
    "YouTube",
    "Linkedin",
  ];

  const atualizarCampoRedeSocial = (campo, valor) => {
    setRedesSociais(
      redesSociais.map((rede) => {
        if (rede.id === redeSocial.id) {
          switch (campo) {
            case "TIPO":
              return { ...redeSocial, tipoRedeSocial: valor };
            case "NOME_USUARIO":
              return { ...redeSocial, nomeUsuario: valor };
            default:
              console.error(`Tipo de campo inválido. Recebido: ${campo}`);
          }
        }
        return rede;
      })
    );
  };

  const removerRedeSocial = () => {
    const redesSociaisItemRemovido = redesSociais.filter(
      (rede) => rede.id !== redeSocial.id
    );
    if (redesSociaisItemRemovido.length === 0) {
      const redeSocialPadrao = {
        id: crypto.randomUUID(),
        tipoRedeSocial: "",
        nomeUsuario: "",
      };
      redesSociaisItemRemovido.push(redeSocialPadrao);
    }
    setRedesSociais(redesSociaisItemRemovido);
  };

  return (
    <>
      <div className="rede-input-wrapper">
        <div className="perfil-wrapper-label">
          <text className="perfil-text-label">Rede social</text>
        </div>
        <Dropdown
          opcoes={opcoes_redes_sociais.filter(
            (opcao) =>
              !redesSociais.map((rede) => rede.tipoRedeSocial).includes(opcao)
          )}
          valor={redeSocial.tipoRedeSocial}
          onChange={(tipo) => {
            atualizarCampoRedeSocial("TIPO", tipo);
          }}
        />
      </div>
      <div className="usuario-input-wrapper">
        <div className="perfil-wrapper-label">
          <text className="perfil-text-label">Nome de usuário</text>
        </div>
        <Input
          value={redesSociais.find((r) => r.id === redeSocial.id).nomeUsuario}
          onChange={(valor) => atualizarCampoRedeSocial("NOME_USUARIO", valor)}
          validationRules={VALIDATION_RULES_BY_FIELD.nome}
          errorsVisible
        />
      </div>
      <button className="remover-rede-botao" onClick={removerRedeSocial}>
        remover rede social
      </button>
    </>
  );
};

export default RedeSocialFormulario;
