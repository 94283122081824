import "../../../styles/Acordeon.css";

import icAcordeon1 from "../../../images/ic-seta1.png";
import icAcordeon2 from "../../../images/ic-seta2.png";
import icAcordeon3 from "../../../images/ic-check.png";
import icAcordeon4 from "../../../images/ic-checkbox.png";
import { click } from "@testing-library/user-event/dist/click";

export const AcordeonAulas = ({
  aulas,
  numInicial = 1,
  aulaExpandida,
  setAulaExpandida,
  aulaDireto,
  aulaEscolhida,
}) => {
  return (
    <ul className="acordeon-container">
      {aulas &&
        aulas.map((aula, index) => {
          return (
            <AcordeonItem
              key={index}
              aula={aula}
              expandido={aulaExpandida && aulaExpandida === index + 1}
              numAula={index + numInicial}
              setAulaExpandida={setAulaExpandida}
              onItemClicked={aulaDireto}
              aulaEscolhida={aulaEscolhida}
            />
          );
        })}
    </ul>
  );
};

// TODO: Implementar check de passo concluído
const AcordeonItem = ({
  aula,
  numAula,
  expandido,
  setAulaExpandida,
  onItemClicked,
  aulaEscolhida,
}) => {
  const habilitado = aula?.passos && aula.passos.length > 0;

  const handleClick = () => {
    if (onItemClicked) {
      onItemClicked(numAula);
      aulaEscolhida(numAula);
    }
  };

  return (
    <li
      className="acordeon-item"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div className="acordeon-header">
        <span
          className={
            habilitado ? "acordeon-text-main" : "acordeon-text-main-off"
          }
        >
          AULA {numAula}
        </span>
        {expandido ? (
          <img
            src={icAcordeon2}
            alt="acordeon-img-seta-cima"
            className="acordeon-img-seta-cima"
            onClick={() => setAulaExpandida(null)}
          />
        ) : (
          <img
            src={icAcordeon1}
            alt="acordeon-img-seta-baixo"
            className={
              false ? "acordeon-img-seta-baixo" : "acordeon-img-seta-baixo-off"
            }
            onClick={() => setAulaExpandida(numAula)}
          />
        )}
      </div>
      <div
        className={
          expandido ? "acordeon-wrapper-child-ativo" : "acordeon-wrapper-child"
        }
      >
        <span className="acordeon-text-child">{aula?.titulo}</span>
      </div>
    </li>
  );
};
