export const getLocalRefreshToken = () => {
  return JSON.parse(localStorage.getItem("refreshToken"));
};

export const getLocalAccessToken = () => {
  return JSON.parse(localStorage.getItem("accessToken"));
};

export const updateLocalRefreshToken = (token) => {
  localStorage.setItem("refreshToken", JSON.stringify(token));
};

export const updateLocalAccessToken = (token) => {
  localStorage.setItem("accessToken", JSON.stringify(token));
};

export const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
