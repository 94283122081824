import { useState } from "react";

import "../../styles/Acesso.css";

import { LoginContainer } from "./LoginContainer";

export const AcessoForm = () => {
  return (
    <>
      <h1 className="acesso-h1-header">ACESSAR SUA CONTA</h1>
      <LoginContainer />
    </>
  );
};
