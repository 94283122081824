// Importar Componentes:
import "../styles/ButtonZap.css";

// Importar Imagens:
import icMega from "../images/ic-megafone.png";

export const ButtonZap = ({ link, message, callback }) => {
  return (
    <a
      className="buttonzap-wrapper"
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={callback}
    >
      <span className="buttonzap-link">{message || "Chama no Zap!"}</span>
      <img
        src={icMega}
        alt="buttonzap-img-megafone"
        className="buttonzap-img-megafone"
      />
    </a>
  );
};
