import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Input } from "../components";
import "../styles/Ativacao.css";

import { putNovaSenha } from "../services/redefinir-senha";

import hlAtivacao from "../images/hl-rabisco2.png";
import icUser from "../images/ic-user1.png";

// Importar validações de senha
import {
  minLengthRule,
  lowerCaseRule,
  upperCaseRule,
  specialCharacterRule,
  confirmPasswordRule,
  allValidValuesRules,
} from "../utils/validations";

import { passwordRulesMessages } from "../utils/passwordRules";

// Estrutura da Página:
export const AlterarSenha = () => {
  const navigate = useNavigate();

  const [senha, setSenha] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");

  const initialValidRules = {
    minLength: false,
    specialChar: false,
    upperCase: false,
    lowerCase: false,
    confirmPassword: false,
  };

  const [validRules, setValidRules] = useState(initialValidRules);

  useEffect(() => {
    setValidRules({
      ...validRules,
      minLength: minLengthRule(novaSenha),
      specialChar: specialCharacterRule(novaSenha),
      upperCase: upperCaseRule(novaSenha),
      lowerCase: lowerCaseRule(novaSenha),
      confirmPassword: confirmPasswordRule(novaSenha, confirmarSenha),
    });
  }, [novaSenha]);

  useEffect(() => {
    setValidRules({
      ...validRules,
      confirmPassword: confirmPasswordRule(novaSenha, confirmarSenha),
    });
  }, [confirmarSenha]);

  const enviarAlteracaoSenha = () => {
    const payload = {
      senha,
      novaSenha,
      confirmarSenha,
    };

    putNovaSenha(payload)
      .then(() => navigate("/area-do-aluno"))
      .catch((e) => console.log(`Error: ${e}`));
  };

  return (
    <div className="ativacao-container">
      <div className="ativacao-content">
        <Header />
        <div className="ativacao-section">
          <div className="ativacao-wrapper">
            <div className="ativacao-wrapper-header">
              <h1 className="ativacao-h1-header">Altere sua senha</h1>
              <img
                src={hlAtivacao}
                alt="ativacao-img-header"
                className="ativacao-img-header"
              />
            </div>
            <div className="ativacao-form">
              <div className="ativacao-wrapper-label">
                <text className="ativacao-text-label">Senha</text>
              </div>
              <Input
                value={senha}
                onChange={setSenha}
                placeholder="Senha"
                type="password"
              />
              <div className="ativacao-wrapper-label">
                <text className="ativacao-text-label">Nova Senha</text>
              </div>
              <Input
                value={novaSenha}
                onChange={setNovaSenha}
                placeholder="Nova Senha"
                type="password"
              />
              <div className="ativacao-wrapper-label">
                <text className="ativacao-text-label">Confirmar Senha</text>
              </div>
              <Input
                value={confirmarSenha}
                onChange={setConfirmarSenha}
                placeholder="Confirmar Senha"
                type="password"
              />

              {passwordRulesMessages.map((item) => (
                <div
                  key={JSON.stringify(item)}
                  className={
                    validRules && validRules[item.rule]
                      ? `ativacao-rules-label valido ${item.label}`
                      : "ativacao-rules-label"
                  }
                >
                  {item.message}
                </div>
              ))}

              <button
                className={
                  senha.length > 0 && allValidValuesRules(validRules)
                    ? "ativacao-button-login"
                    : "ativacao-button-login disabled"
                }
                onClick={enviarAlteracaoSenha}
              >
                <img
                  src={icUser}
                  alt="ativacao-img-user"
                  className="ativacao-img-user"
                />
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
