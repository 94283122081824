import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ButtonZap, Header, useModal } from "../../components";
import { useGetCursos } from "../../queries/curso";

import { Banner } from "./Banner";
import { SobreCurso, SobreSetorOuColetivo } from "./SobreCurso";
import { DetalhesCurso } from "./DetalhesCurso";
import { InformacoesGerais } from "./InformacoesGerais";
import { ModalMatricula } from "./Matricula/ModalMatricula";
import { cursosById, sobreByCursoId } from "./setoriais";

import "../../styles/Curso.css";

const isCursoSetorial = (slugCurso) => {
  return Object.keys(sobreByCursoId).includes(slugCurso);
};

export const SobreCursoComSetores = ({ slugCurso }) => {
  if (!slugCurso) return;

  if (isCursoSetorial(slugCurso)) {
    return <SobreCurso TextoSobreCurso={sobreByCursoId[slugCurso]} />;
  }

  return <SobreCurso />;
};

export const Curso = () => {
  const { slugCurso } = useParams();
  const [curso, setCurso] = useState(null);
  const [subtitulo, setSubtitulo] = useState("");

  const { data } = useGetCursos();

  const { visible, setVisible } = useModal();

  const statusCurso = slugCurso === "mtst" ? "encerrado" : "Inscreva-se";

  useEffect(() => {
    if (!slugCurso) return;
    if (Object.keys(cursosById).includes(slugCurso)) {
      setCurso(cursosById[slugCurso]);
      return;
    }
    const cursos = data?.data || [];
    setCurso(cursos.find((c) => c.slug === slugCurso));
  }, [data, slugCurso]);

  const onClickMatricular =
    statusCurso === "a iniciar"
      ? () => {
          setVisible(true);
        }
      : null;

  return (
    <>
      <Header />
      <div className="curso-container">
        {curso && (
          <>
            <Banner
              banner={curso?.banner}
              titulo={curso.titulo}
              subtitulo={
                slugCurso === "mtst"
                  ? "Introdução ao trabalho de base"
                  : curso?.subtitulo
              }
              formadores={
                curso?.setorOuColetivo?.nome
                  ? `com o SETOR DE ${curso.setorOuColetivo.nome.toUpperCase()} DO MTST`
                  : null
              }
              status={statusCurso}
              inscrito={false}
              onClickMatricular={onClickMatricular}
              slugCurso={slugCurso}
            />

            <div className="curso-corpo">
              <div className="curso-corpo-colunas">
                <div className="curso-corpo-coluna-esquerda">
                  {isCursoSetorial(slugCurso) ? (
                    <>
                      <p className="curso-secao-informacao-conteudo">
                        *Abertura do curso sujeita à disponibilidade do MTST no
                        seu Estado
                        <br />
                        <br />
                        A data de inscrição será de 02/01 à 10/01.
                        <br />
                        Fique atento ao seu grupo de Whatsapp, alguns Estados
                        terão atividades presenciais no dia 07/01.
                      </p>
                    </>
                  ) : null}
                  {curso.setorOuColetivo ? (
                    <SobreSetorOuColetivo
                      setorOuColetivo={curso.setorOuColetivo}
                    />
                  ) : null}
                  <SobreCursoComSetores slugCurso={slugCurso} />
                  <DetalhesCurso curso={curso} />
                </div>

                <div className="curso-corpo-coluna-direita">
                  <InformacoesGerais curso={curso}>
                    <ButtonZap link={curso.linkZap} />
                  </InformacoesGerais>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ModalMatricula
        nomeCurso={curso?.titulo}
        comunidade={"https://chat.whatsapp.com/DuBBpfkAjRb6YrDFMHgV2D"}
        visible={visible}
        onDismiss={() => {
          setVisible(false);
        }}
      />
    </>
  );
};
