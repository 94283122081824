import { useMutation, useQuery } from "@tanstack/react-query";

import { apiV1WithToken } from "../services/api-v1";

export const useResponderQuestao = (passoId, questaoId) => {
  return useMutation({
    mutationFn: async (resposta) => {
      return await apiV1WithToken.post(
        `/passos/${passoId}/questoes/${questaoId}/respostas`,
        resposta
      );
    },
  });
};

export const useBuscarResposta = (passoId, questaoId) => {
  return useQuery({
    queryKey: ["respostas", passoId, questaoId],
    queryFn: async () => {
      return await apiV1WithToken.get(
        `/passos/${passoId}/questoes/${questaoId}/respostas`
      );
    },
    enabled: !!passoId && !!questaoId,
  });
};
