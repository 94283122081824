import fundoEducacao from "../../../images/cursos-setores/fundoEducacao.png";
import imagemDireitaEducacao from "../../../images/cursos-setores/imagemDireitaEducacao.png";
import card from "../../../images/card-edu.png";

export const SobreCursoEducacao = () => {
  return (
    <>
      Se a educação é sempre um ato político, isso ainda é mais certo nas
      atividades de educação popular no MTST!
      <br />
      <br />
      Por meio do curso, iremos propor reflexões a partir de problemas reais,
      experimentados por militantes do setor de educação, em suas ações
      educativas. Buscamos desenvolver uma experiência que coloque em diálogo o
      pensamento teórico e a prática educadora, dentro da realidade do
      movimento, para então, convidarmos ao desafio de construir uma pedagogia
      sem teto.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O Setor de Educação é formado por pessoas que participam da jornada de
      ensinar e aprender na luta com o povo. Para isso, propomos atividades que
      partam da experiência viva dos educandos em sua luta diária, à qual nos
      unimos. Construímos desde rodas de leitura à reforços escolares, mas temos
      como eixo principal as atividades socioeducativas com as nossas crianças.
      <br />
      <br />
      Não é necessário ser professor para participar: basta ter disposição para,
      como educador, ser também um educando, que se forma em sua prática
      reflexiva com o povo.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const educacao = {
  id: "educacao",
  titulo: "Construindo uma pedagogia sem-teto",
  categoria: "Educação",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Por que um setor de educação em um movimento de moradia?",
      formador: null,
    },
    {
      titulo:
        "Sem-Tetinhos em ação, pra fazer revolução: Como é a educação nas ocupações e espaços do movimento",
      formador: null,
    },
    {
      titulo: "O papel da educação popular para o enraizamento nos territórios",
      formador: null,
    },
    {
      titulo: "Construindo uma pedagogia sem teto",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#B9D5F1",
    corTitulo: "#FEFCFB",
    fundo: fundoEducacao,
    itemDireita: imagemDireitaEducacao,
  },
  materiais: "Material para anotação",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Educação",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VIDEO+EDUCAÇÃO.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5519997208061",
};
