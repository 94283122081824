const SecaoFormulario = ({ children, titulo }) => {
  return (
    <fieldset className="secao-formulario-fieldset">
      <legend className="secao-formulario-fieldset-legend">{titulo}</legend>
      {children}
    </fieldset>
  );
};

export default SecaoFormulario;
