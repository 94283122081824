import { Header } from "../../components";

import { AcessoForm } from "./AcessoForm";

import "../../styles/Acesso.css";

export const AcessoPagina = () => {
  return (
    <div className="acesso-content">
      <Header showButton={false} />
      <div className="acesso-card">
        <AcessoForm />
      </div>
    </div>
  );
};
