import { AuthService } from "../../services/auth";

export const signUp = (nome, email, password, telefone) => (dispatch) => {
  return AuthService.signUp(nome, email, password, telefone).then(
    (data) => {
      dispatch({
        type: "SIGNUP_SUCCESS",
        payload: {
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
        },
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: "SIGNUP_FAIL",
        payload: error.response?.data,
      });
    }
  );
};

export const verify = (signupId) => (dispatch) => {
  return AuthService.verify(signupId).then(
    (data) => {
      dispatch({
        type: data.success ? "VERIFY_SUCCESS" : "VERIFY_FAIL",
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: "VERIFY_FAIL",
      });
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: "LOGIN_FAIL",
      });
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: "LOGOUT",
  });
};

export const refreshToken = (accessToken) => (dispatch) => {
  AuthService.refreshToken();

  dispatch({
    type: "REFRESH_TOKEN",
    payload: accessToken,
  });
};
