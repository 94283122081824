import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Perfil.css";
import { Header } from "../../components";
import SecaoFormulario from "./components/SecaoFormulario";
import FormInfoSocial from "./components/RedeSocialWrapper";
import InfoContato from "./components/InfoContato";
import InfoPessoal from "./components/InfoPessoal";
import FormHeader from "./components/FormularioHeader";
import { useUserQuery } from "../../queries/user";
import {
  buscarFormularioPerfil,
  enviarPerfil,
} from "../../store/actions/perfil";

// Todo(Oda): Extrair componente modal
import { DadosSalvosModal } from "../Aulas/components";

const Perfil = () => {
  const dispatch = useDispatch();
  const perfil = useSelector((store) => store.perfil);

  const [statusFetch, setStatusFetch] = useState("ocioso");
  const [modalSalvo, setModalSalvo] = useState(false);

  const [nomeAluno, setNomeAluno] = useState("");
  const [numeroMatricula, setNumeroMatricula] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [pronomeSelecionado, setPronomeSelecionado] = useState("");
  const [cep, setCep] = useState("");
  const [numeroEndereco, setNumeroEndereco] = useState("");
  const [complementoEndereco, setComplementoEndereco] = useState("");
  const [ocupacaoAtual, setOcupacaoAtual] = useState("");
  const [organizacao, setOrganizacao] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [areasInteresseSelecionadas, setAreasInteresseSelicionadas] = useState(
    []
  );
  const [redesSociais, setRedesSociais] = useState([
    { id: crypto.randomUUID(), tipoRedeSocial: "", nomeUsuario: "" },
  ]);

  const { data: usuario } = useUserQuery();

  const fetchData = async () => {
    if (statusFetch !== "ocioso") {
      return;
    }
    setStatusFetch("carregando");
    await Promise.all([dispatch(buscarFormularioPerfil())]);
    setStatusFetch("success");
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (statusFetch === "success") {
      setNomeAluno(usuario.name);
      setNumeroMatricula(usuario.signup_id);
      setDataNascimento(perfil.dataNascimento);
      setPronomeSelecionado(perfil.pronomes);
      setCep(perfil.cep);
      setNumeroEndereco(perfil.numeroEndereco);
      setComplementoEndereco(perfil.complementoEndereco);
      setOcupacaoAtual(perfil.ocupacaoAtual);
      setOrganizacao(perfil.organizacao);
      setEmail(usuario.email);
      setWhatsapp(usuario.phone);
      setAreasInteresseSelicionadas(perfil.areasInteresse);
      setRedesSociais(perfil.redesSociais);
    }
  }, [statusFetch]);

  const podeSalvar = Boolean(
    nomeAluno &&
      cep &&
      numeroEndereco &&
      complementoEndereco &&
      redesSociais[0].tipoRedeSocial &&
      redesSociais[0].nomeUsuario
  );

  const onSave = () => {
    if (podeSalvar) {
      dispatch(
        enviarPerfil({
          nomeAluno,
          dataNascimento,
          pronomeSelecionado,
          cep,
          numeroEndereco,
          complementoEndereco,
          ocupacaoAtual,
          organizacao,
          areasInteresseSelecionadas,
          redesSociais,
        })
      );
      setModalSalvo(true);
    }
  };

  return (
    <div className="conteudo-container">
      <DadosSalvosModal
        isVisible={modalSalvo}
        onDismiss={() => {
          setModalSalvo(false);
          setTimeout(() => window.location.reload(), 50);
        }}
      />
      <Header />
      <div className="formulario-container">
        <FormHeader nomeAlunoAtual={usuario.name} />
        <SecaoFormulario titulo="Informações pessoais">
          <InfoPessoal
            nomeAluno={nomeAluno}
            setNomeAluno={setNomeAluno}
            numeroMatricula={numeroMatricula}
            setNumeroMatricula={setNumeroMatricula}
            dataNascimento={dataNascimento}
            setDataNascimento={setDataNascimento}
            pronomeSelecionado={pronomeSelecionado}
            setPronomeSelecionado={setPronomeSelecionado}
            cep={cep}
            setCep={setCep}
            numero={numeroEndereco}
            setNumero={setNumeroEndereco}
            complemento={complementoEndereco}
            setComplemento={setComplementoEndereco}
            ocupacaoAtual={ocupacaoAtual}
            setOcupacaoAtual={setOcupacaoAtual}
            organizacao={organizacao}
            setOrganizacao={setOrganizacao}
          />
        </SecaoFormulario>
        <SecaoFormulario titulo="Informações de contato">
          <InfoContato
            email={email}
            setEmail={setEmail}
            whatsapp={whatsapp}
            setWhatsapp={setWhatsapp}
          />
        </SecaoFormulario>
        <SecaoFormulario titulo="Social">
          <FormInfoSocial
            redesSociais={redesSociais}
            setRedesSociais={setRedesSociais}
          />
          <div className="secao-formulario-aviso-container">
            <text className="secao-formulario-aviso-text">
              *Mínimo de uma rede social
            </text>
          </div>
        </SecaoFormulario>
        <button
          className={`salvar-botao ${podeSalvar ? "" : "desabilitado"}`}
          onClick={onSave}
        >
          {" "}
          Completar inscrição
        </button>
      </div>
    </div>
  );
};

export default Perfil;
