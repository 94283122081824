const initialState = { perfil: {}, carregado: false };

export const PerfilReducer = function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "PERFIL":
      return {
        ...state,
        carregado: true,
        ...payload,
      };
    case "PERFIL_ENVIADO":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
