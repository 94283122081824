import icFechar from "../../images/ic-fechar.png";

import "./modal.css";

export const Modal = ({
  visible,
  showCloseButton = true,
  onDismiss,
  children,
}) => {
  return visible ? (
    <div className="modal-overlay">
      <div className="modal">
        {showCloseButton ? (
          <button className="modal-fechar" onClick={onDismiss}>
            <img src={icFechar} alt="fechar modal" />
          </button>
        ) : null}
        <div className="modal-body">{children}</div>
      </div>
    </div>
  ) : null;
};

export * from "./useModal";
