const initialState = {
  cursos: { introducao: 0 },
};

export const EscolaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ATUALIZAR_CURSO_INTRODUCAO":
      return { ...state, ...{ cursos: { introducao: action.payload } } };
    default:
      return state;
  }
};
