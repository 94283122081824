import hlCurso2 from "../../images/hl-seta2.png";

const MateriaisDoCurso = ({ materiais }) => {
  return (
    <div className="curso-secao-informacao">
      <h1 className="curso-secao-informacao-titulo">O QUE VOCÊ VAI PRECISAR</h1>
      <text className="curso-secao-informacao-conteudo">
        As etapas presenciais são em sua maioria em ocupações do MTST,
        importante levar seu prato, caneca, talher e elementos de higiene. Se
        for acompanhar o curso online é importante considerar que não gravamos
        as aulas, certifique-se de ter a disposição o tempo para às aulas e
        leituras.
      </text>
    </div>
  );
};

const Aula = ({ indice, titulo }) => {
  return (
    <div className="curso-wrapper-aula">
      <text className="curso-section2-text-numero">
        AULA {String(indice).padStart(2, "0")}
      </text>
      <text className="curso-section2-text-aula">{titulo}</text>
    </div>
  );
};

const Aulas = ({ aulas }) => {
  return (
    <div className="curso-section2-wrapper2">
      <div className="curso-section2-wrapper-hl">
        <h1 className="curso-secao-informacao-titulo">O QUE VAMOS DISCUTIR</h1>
        <img
          src={hlCurso2}
          alt="curso-section2-img-rabisco2"
          className="curso-section2-img-rabisco2"
        />
      </div>
      <div className="curso-wrapper-aulas">
        {aulas.map((aula, index) => {
          return <Aula key={index} indice={index + 1} titulo={aula.titulo} />;
        })}
      </div>
    </div>
  );
};

export const DetalhesCurso = ({ curso }) => {
  return (
    <>
      <Aulas aulas={curso.aulas} />
    </>
  );
};
