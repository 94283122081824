// Importar Componentes:
import { React, useState } from "react";
import { Header } from "../components";
import { useDispatch, useSelector } from "react-redux";
import "../styles/Conteudos.css";

// Importar Imagens
import txHojeNaLuta from "../images/tx-hoje-na-luta.png";
import txPaisesOuvintes from "../images/tx-paises-ouvintes.png";
import txPodcasts from "../images/tx-podcasts.png";
import txProducoes from "../images/tx-producoes.png";
import icHojeNaLuta from "../images/ic-hoje-na-luta-vermelho.png";
import icFechar from "../images/ic-fechar.png";
import plAcontHist from "../images/pl-acontecimentos-historicos.png";
import plAnarquismo from "../images/pl-anarquismo.png";
import plAntiImperialismo from "../images/pl-anti-imperialismo.png";
import plAntiRacismo from "../images/pl-anti-racismo.png";
import plCienciaTecnologia from "../images/pl-ciencia-tecnologia.png";
import plComunismo from "../images/pl-comunismo.png";
import plCulturaEntretenimento from "../images/pl-cultura-entretenimento.png";
import plEsportes from "../images/pl-esportes.png";
import plInimigosDoPovo from "../images/pl-inimigos-do-povo.png";
import plLgbt from "../images/pl-lgbt.png";
import plLutasPovoBrasileiro from "../images/pl-lutas-povo-brasileiro.png";
import plLutasPovosIndigenas from "../images/pl-lutas-povos-indigenas.png";
import plLutasPovoLatino from "../images/pl-lutas-povo-latino.png";
import plMulheres from "../images/pl-mulheres.png";
import plReligiosidade from "../images/pl-religiao.png";

const PLAYLISTS_IFRAMES = {
  acontecimentosHistoricos: {
    titulo: "Acontecimentos Históricos",
    iframe: (
      <iframe
        title="acontecimentos-historicos"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/4MDYcevkTbRmR3zU31noJB?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  anarquismo: {
    titulo: "Anarquismo",
    iframe: (
      <iframe
        title="anarquismo"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/3TezV3cX9os6IQPwlJPgYN?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  antiImperialismo: {
    titulo: "Anti-Imperilismo",
    iframe: (
      <iframe
        title="anti-imperialismo"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/0MawgTXiQiOSW4mPYVGBiS?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  antiRacismo: {
    titulo: "Anti-Racismo",
    iframe: (
      <iframe
        title="anti-racismo"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/1CwwCIcC7pAbbOk3NJLhts?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  cienciaTecnologia: {
    titulo: "Ciência e Tecnologia",
    iframe: (
      <iframe
        title="ciencia-tecnologia"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/6okYsabB1DJsfBGhjwi0w4?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  comunismo: {
    titulo: "Comunismo",
    iframe: (
      <iframe
        title="comunismo"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/3SAeN7MlEnX4iQc2mamMQS?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  culturaEntretenimento: {
    titulo: "Cultura e Entretenimento",
    iframe: (
      <iframe
        title="cultura-entretenimento"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/75hnxw0mdKMUqs4UXp8wS4?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  esportes: {
    titulo: "Esportes",
    iframe: (
      <iframe
        title="esportes"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/29E6js6u2zkbFJJJ3GzNW1?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  inimigosDoPovo: {
    titulo: "Inimigos do Povo",
    iframe: (
      <iframe
        title="inimigos-do-povo"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/3KcvyuYakdw07uW6VQaepQ?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  lgbt: {
    titulo: "LGBTQIA+",
    iframe: (
      <iframe
        title="lgbtqia+"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/2zEF6LhHVCKrD12FWyZr7w?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  lutasPovoBrasileiro: {
    titulo: "Lutas do povo Brasileiro",
    iframe: (
      <iframe
        title="lutas-povo-brasileiro"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/7xu9EOUkN83jgYVvCXHPIx?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  lutasPovosIndigenas: {
    titulo: "Lutas dos povos indígena",
    iframe: (
      <iframe
        title="lutas-povos-indigenas"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/5cfqSfyjmq3WEs3iAAXRhU?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  lutasPovoLatino: {
    titulo: "Lutas do povo latino",
    iframe: (
      <iframe
        title="lutas-povo-latino"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/4fr2ShhJoFYDTEQsdH3BGK?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  mulheres: {
    titulo: "Mulheres",
    iframe: (
      <iframe
        title="mulheres"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/2hQLAUcERaqbClKUPDblfu?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
  religiosidade: {
    titulo: "Religiosidade",
    iframe: (
      <iframe
        title="Religiosidade"
        style={{ "border-radius": "12px" }}
        src="https://open.spotify.com/embed/playlist/1MDmWaRMbCO39G7mODlYgO?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ),
  },
};

const PlaylistModal = ({ playlistAtual, onClose }) => {
  return (
    <div className="conteudos-playlist-modal">
      <div className="conteudos-playlist-modal-header">
        <h3 className="conteudos-playlist-modal-titulo">
          {PLAYLISTS_IFRAMES[playlistAtual].titulo}
        </h3>
        <button className="conteudos-playlist-modal-fechar" onClick={onClose}>
          <img src={icFechar} alt="fechar playlist" />
        </button>
      </div>
      {PLAYLISTS_IFRAMES[playlistAtual].iframe}
    </div>
  );
};

export const Conteudos = () => {
  const [playlistAtual, setPlaylistAtual] = useState("");

  const PlaylistCard = ({ titulo, playlistSlug, imgSrc }) => {
    return (
      <button
        className="conteudos-playlist-botao"
        onClick={() => setPlaylistAtual(playlistSlug)}
      >
        <img
          className="conteudos-playlist-img"
          src={imgSrc}
          alt={`playlist ${titulo}`}
        />
      </button>
    );
  };

  return (
    <div className="conteudos-container">
      <Header />
      <div className="conteudos-content">
        <h1 className="conteudos-titulo">QUEM SABE MAIS, LUTA MELHOR!</h1>
        <div className="conteudos-hoje-na-luta-imgs">
          <img src={txHojeNaLuta} alt="hoje na luta" width={278} height={85} />
          <img
            src={icHojeNaLuta}
            alt="icone hoje na luta"
            width={93}
            height={83}
          />
        </div>
        <section className="conteudos-descricao">
          <p className="conteudos-descricao1">
            O <b className="conteudos-nome-projeto">Hoje na Luta</b> nasceu em
            maio de 2020, durante a pandemia de covid-19, como um projeto de
            formação diária à distância, com áudios enviados diretamente aos
            coordenadores do MTST, quando não era possível realizar formações
            presenciais.
          </p>
          <p className="conteudos-descricao2">
            O sucesso foi tanto, que o Hoje na Luta se tornou um podcast, e
            agora está disponível não só para os coordenadores, mas também para
            seguidores e apoiadores do MTST, nas principais plataformas de
            reprodução de podcast e também no YouTube.
          </p>
        </section>
        <section className="hilights-container">
          <img className="hilight-cards" src={txProducoes} alt="" />
          <img className="hilight-cards" src={txPaisesOuvintes} alt="" />
          <img className="hilight-cards" src={txPodcasts} alt="" />
        </section>
        <section className="conteudos-playlists-section">
          <h1 className="conteudos-playlists-titulo">NOSSAS PLAYLISTS</h1>
          <PlaylistCard
            titulo="Acontecimentos Históricos"
            playlistSlug="acontecimentosHistoricos"
            imgSrc={plAcontHist}
          />
          <PlaylistCard
            titulo="Anarquismo"
            playlistSlug="anarquismo"
            imgSrc={plAnarquismo}
          />
          <PlaylistCard
            titulo="Anti-Imperialismo"
            playlistSlug="antiImperialismo"
            imgSrc={plAntiImperialismo}
          />
          <PlaylistCard
            titulo="Anti-Racismo"
            playlistSlug="antiRacismo"
            imgSrc={plAntiRacismo}
          />
          <PlaylistCard
            titulo="Ciência e Técnologia"
            playlistSlug="cienciaTecnologia"
            imgSrc={plCienciaTecnologia}
          />
          <PlaylistCard
            titulo="Comunismo"
            playlistSlug="comunismo"
            imgSrc={plComunismo}
          />
          <PlaylistCard
            titulo="Cultura e Entretenimento"
            playlistSlug="culturaEntretenimento"
            imgSrc={plCulturaEntretenimento}
          />
          <PlaylistCard
            titulo="Esportes"
            playlistSlug="esportes"
            imgSrc={plEsportes}
          />
          <PlaylistCard
            titulo="Inimigos do Povo"
            playlistSlug="inimigosDoPovo"
            imgSrc={plInimigosDoPovo}
          />
          <PlaylistCard titulo="LGBTQIA+" playlistSlug="lgbt" imgSrc={plLgbt} />
          <PlaylistCard
            titulo="Lutas do povo Brasileiro"
            playlistSlug="lutasPovoBrasileiro"
            imgSrc={plLutasPovoBrasileiro}
          />
          <PlaylistCard
            titulo="Lutas dos povos indígenas"
            playlistSlug="lutasPovosIndigenas"
            imgSrc={plLutasPovosIndigenas}
          />
          <PlaylistCard
            titulo="Lutas do povo latino"
            playlistSlug="lutasPovoLatino"
            imgSrc={plLutasPovoLatino}
          />
          <PlaylistCard
            titulo="Mulheres"
            playlistSlug="mulheres"
            imgSrc={plMulheres}
          />
          <PlaylistCard
            titulo="Religiosidade"
            playlistSlug="religiosidade"
            imgSrc={plReligiosidade}
          />
        </section>
      </div>
      {playlistAtual && (
        <PlaylistModal
          playlistAtual={playlistAtual}
          onClose={() => setPlaylistAtual("")}
        />
      )}
    </div>
  );
};
