export const passwordRulesMessages = [
  {
    rule: "minLength",
    label: "minimo",
    message: "Deve ter no mínimo 8 caracteres",
  },
  {
    rule: "upperCase",
    label: "letra-maiuscula",
    message: "Deve conter 1 letra maiúscula",
  },
  {
    rule: "lowerCase",
    label: "letra-minuscula",
    message: "Deve conter 1 letra minúscula",
  },
  {
    rule: "specialChar",
    label: "caracter-especial",
    message: "Deve conter 1 caracter especial (@, $, %, *)",
  },
  {
    rule: "hasNumber",
    label: "numero",
    message: "Deve conter pelo menos um número",
  },
  {
    rule: "confirmPassword",
    label: "confirmar-senha",
    message: "Senhas devem ser iguais",
  },
];
