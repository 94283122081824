import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonZap, FadeSpinner, Modal } from "../../../components";
import { ROUTES } from "../../../routing";
import { AcessoForm } from "../../Acesso";
import {
  useSalvarInformacoesBrigadas,
  useBuscaInformacoesBrigadas,
} from "../../../queries/matricula";

import hlCurso1 from "../../../images/hl-rabisco3.png";

import { InfoExtraMatriculaForm } from "./InfoExtraMatriculaForm";

export const ModalMatricula = ({
  nomeCurso,
  comunidade,
  visible,
  onDismiss,
}) => {
  const isLogged = useSelector((store) => store.auth.isLogged);
  const [passo, setPasso] = useState("cadastro");
  const navigate = useNavigate();
  const salvarInformacoesBrigadas = useSalvarInformacoesBrigadas();
  const salvando = salvarInformacoesBrigadas.isLoading;

  const { data, isFetching, refetch } = useBuscaInformacoesBrigadas();

  useEffect(() => {
    if (isLogged) {
      refetch();
      setPasso("infos extras");
    }
  }, [isLogged]);

  useEffect(() => {
    if (salvarInformacoesBrigadas.isSuccess) {
      setPasso("sucesso");
    }
  }, [salvarInformacoesBrigadas.isSuccess]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setPasso("sucesso");
    }
  }, [data]);

  const onClose = () => {
    if (passo === "sucesso") {
      navigate(ROUTES.areaAluno);
    } else {
      onDismiss();
    }
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onClose}
      showCloseButton={passo !== "sucesso"}
    >
      {passo === "cadastro" ? (
        <AcessoForm />
      ) : passo === "infos extras" ? (
        isFetching ? (
          <FadeSpinner />
        ) : (
          <InfoExtraMatriculaForm
            matricular={salvarInformacoesBrigadas.mutate}
          />
        )
      ) : salvando ? (
        <FadeSpinner />
      ) : (
        <SucessoMatricula nomeCurso={nomeCurso} comunidade={comunidade} />
      )}
    </Modal>
  );
};

const SucessoMatricula = ({ nomeCurso, comunidade }) => {
  const navigate = useNavigate();

  return (
    <div className="sucesso-modal-body">
      <div className="sucesso-header-wrapper">
        <h2 className="sucesso-header">Inscrição concluída!</h2>
        <img
          src={hlCurso1}
          alt="curso-section2-img-rabisco1"
          className="sublinhado-matricula"
        />
      </div>
      <h3>Bem-vindo ao curso "{nomeCurso}"!</h3>
      <h3>
        As informações sobre as aulas serão feitas através da nossa comunidade
        no Whatsapp.
      </h3>
      {comunidade ? (
        <ButtonZap
          link={comunidade}
          message="Entre na comunidade!"
          callback={() => navigate(ROUTES.areaAluno)}
        />
      ) : (
        <button
          onClick={() => navigate(ROUTES.areaAluno)}
          className="acesso-button acesso-button-primary"
        >
          Acessar Sua Área
        </button>
      )}
    </div>
  );
};
