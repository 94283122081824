// Importar Componentes:
import React, { useContext, useEffect } from "react";
import { Header, Button } from "../components";
import { SignupIdContext } from "../contexts";
import "../styles/Errado.css";

// Importar Imagens:
import bgErrado1 from "../images/bg-formiguinhas3.png";
import bgErrado2 from "../images/bg-zebrada1.png";
import bgErrado3 from "../images/bg-formiguinhas4.png";
import bgErrado4 from "../images/bg-zebrada1.png";

import ftErrado1 from "../images/ft-mobile1.png";
import ftErrado2 from "../images/ft-desktop1.png";

// Estrutura da Página:
export const NaoEncontrado = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="errado-container">
      <div className="errado-content">
        <Header />
        <section className="errado-section">
          <img
            src={bgErrado1}
            className="errado-img-formigas1"
            alt="errado-img-formigas1"
          />
          <img
            src={bgErrado3}
            className="errado-img-formigas2"
            alt="errado-img-formigas2"
          />
          <h1 className="errado-h1-ops">OPS,</h1>
          <h1 className="errado-h1-algoerrado">não encontramos a página!</h1>
          <h1 className="errado-h1-porfavor">
            Por favor, verifique o endereço digitado.
          </h1>
        </section>
      </div>
      <img src={ftErrado1} className="errado-img-ft1" alt="errado-img-ft1" />
      <img src={ftErrado2} className="errado-img-ft2" alt="errado-img-ft2" />
    </div>
  );
};
