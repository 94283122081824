import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { logout } from "../../store/actions/auth";
import { ROUTES } from "../../routing";
import { ListaCursos } from "../ListaCursos";

import icClose from "../../images/ic-fechar.png";

import "./Sidebar.css";

const Sidebar = ({ active }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLogged = useSelector((store) => store.auth.isLogged);

  const closeSidebar = () => {
    active(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.reload(false);
    navigate(ROUTES.home);
  };

  return (
    <div className="sidebar-container" sidebar={active}>
      <div className="sidebar-wrapper">
        <ListaCursos fechar={closeSidebar} />
        <div className="sidebar-wrapper-bloco">
          <text className="sidebar-text-main">Conteúdos</text>
          <Link
            className="sidebar-text-child"
            to={ROUTES.contents}
            onClick={closeSidebar}
          >
            Hoje na Luta
          </Link>
        </div>
        <div className="sidebar-wrapper-bloco">
          <text className="sidebar-text-main">Mais</text>
          <HashLink
            className="sidebar-text-child"
            to="/#quem-somos"
            onClick={closeSidebar}
          >
            Quem Somos
          </HashLink>
          <HashLink
            className="sidebar-text-child"
            to="/#apoie"
            onClick={closeSidebar}
          >
            Apoie!
          </HashLink>
          <HashLink
            className="sidebar-text-child"
            to="/#faq"
            onClick={closeSidebar}
          >
            Dúvidas
          </HashLink>
        </div>

        {isLogged ? (
          <>
            <div className="sidebar-wrapper-bloco">
              <text className="sidebar-text-main">Minha Área</text>
              <Link className="sidebar-text-child" to={ROUTES.areaAluno}>
                Área do Aluno
              </Link>
            </div>
            <div className="sidebar-wrapper-bloco">
              <Link className="sidebar-text-main-link" onClick={handleLogout}>
                Sair
              </Link>
            </div>
          </>
        ) : (
          <div className="sidebar-wrapper-bloco">
            <Link className="sidebar-button-login" to={ROUTES.login}>
              ENTRAR
            </Link>
          </div>
        )}

        <div className="sidebar-wrapper-close">
          <img
            src={icClose}
            alt="sidebar-img-close"
            className="sidebar-img-close"
            onClick={closeSidebar}
          />
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
