import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Header, MinhaAgenda } from "../components";
import { useUserQuery } from "../queries/user";
import { buscarFormularioPerfil } from "../store/actions/perfil";
import { generateCertificate } from "../services/certificate";
import { ButtonZap } from "../components";
import { ROUTES } from "../routing";

import { OPCOES_PRONOME } from "./Perfil/components/InfoPessoal";
import { cursosById } from "./Curso/setoriais";

import "../styles/AreaAluno.css";

import bgAluno1 from "../images/bg-lona1.png";
import icUser from "../images/ic-user2.png";
import { BsGoogle } from "react-icons/bs";
import { ModalPermissaoGoogle } from "../components/ModalPermissaoGoogle";

const getBemVinde = (pronome) => {
  switch (pronome) {
    case OPCOES_PRONOME[0]:
      return "BEM VINDA À ÁREA DO ALUNO";
    case OPCOES_PRONOME[1]:
      return "BEM VINDO À ÁREA DO ALUNO";
    case OPCOES_PRONOME[2]:
    default:
      return "BEM VINDE À ÁREA DO ALUNO";
  }
};

export const AreaAluno = () => {
  return (
    <div className="aluno-container">
      <div className="aluno-content">
        <Header />
        <AlunoSection1 />
      </div>
      {/* <div className="aluno-content">
        <AlunoSection2 />
      </div> */}
      <div className="aluno-content">
        <CarrosselCursos />
      </div>
    </div>
  );
};

const AlunoSection1 = () => {
  const dispatch = useDispatch();
  const perfil = useSelector((store) => store.perfil);
  const escolhaSetores = useSelector((store) => store.escolhaSetores);
  const [modalPermissaoGoogleVisible, setModalPermissaoGoogleVisible] =
    useState(false);

  const { data: usuario } = useUserQuery();

  const [tabState, setTabState] = useState(1);

  useEffect(() => {
    if (!perfil.carregado && usuario?._id) {
      dispatch(buscarFormularioPerfil(usuario._id));
    }
  }, [dispatch, perfil, usuario]);

  const toggletab = (index) => {
    setTabState(index);
  };

  const downloadCertificate = () => {
    generateCertificate("CursaoBrigada2023", usuario);
  };

  const saudacao = usuario?.name
    ? "OLÁ, " + usuario.name.toUpperCase() + "!"
    : "OLÁ!";

  function salvarAgendaNoCalendario() {
    setModalPermissaoGoogleVisible(true);
    // aki deveria salvar o id do curso no storage ou passar para o modal de alguma forma
  }

  return (
    <div className="aluno-section1">
      <div className="aluno-section1-container">
        <div className="aluno-section1-wrapper1">
          <img
            src={icUser}
            alt="aluno-section1-img-user"
            className="aluno-section1-img-user"
          />
          <h1 className="aluno-section1-h1-bemvindo">
            {saudacao}
            <br />
            <span className="aluno-section1-h1-bemvindo-red">
              {getBemVinde(perfil.pronomes)}
            </span>
          </h1>
          {/* <button
            className="aluno-button-edit"
            onClick={() => navigate(ROUTES.profile)}
          >
            Editar Perfil
          </button> */}
        </div>
        {!!escolhaSetores?.primeiraOpcao ? (
          <div className="aluno-cursos-modulo2-titulos">
            <h1>Pré-inscrição módulo 2:</h1>
            <br></br>
            <h2>Primeira: {escolhaSetores.primeiraOpcao}</h2>
            <br></br>
            <h2>Segunda: {escolhaSetores.segundaOpcao}</h2>
            <br></br>
            {escolhaSetores.coletivo ? (
              <h2>Coletivo: {escolhaSetores.coletivo}</h2>
            ) : null}
          </div>
        ) : null}
        <div className="aluno-section1-wrapper2">
          <h1 className="aluno-section1-h1-titulo" id="meus-cursos">
            MEUS CURSOS
          </h1>
          <div className="aluno-section1-wrapper-cursos">
            <button
              className={
                tabState === 1
                  ? "aluno-section1-text-ativo"
                  : "aluno-section1-text-cursos"
              }
              onClick={() => toggletab(1)}
            >
              EM ANDAMENTO
            </button>
            <button
              className={
                tabState === 2
                  ? "aluno-section1-text-ativo"
                  : "aluno-section1-text-cursos"
              }
              onClick={() => toggletab(2)}
            >
              CONCLUÍDOS
            </button>
          </div>
          {/* TAB DE CURSOS EM ANDAMENTO */}
          <div
            className={
              tabState === 1
                ? "aluno-section1-wrapper-cards"
                : "aluno-section1-wrapper-inativo"
            }
          >
            <div className="aluno-section1-wrapper-nenhum">
              {"Escolha um curso setorial"}
            </div>
          </div>
          {/* TAB DE CURSOS CONCLUIDOS */}
          <div
            className={
              tabState === 2
                ? "aluno-section1-wrapper-cards"
                : "aluno-section1-wrapper-inativo"
            }
          >
            <CardCurso />
          </div>
        </div>
        {/* CALENDÁRIO DE ATAIVIDADES */}
        <div className="aluno-section1-wrapper3">
          <div className="aluno-section1-wrapper3-row">
            <h1 className="aluno-section1-h1-titulo" id="minha-agenda">
              MINHA AGENDA
            </h1>

            {/*<button
              className="aluno-section1-button"
              onClick={() => salvarAgendaNoCalendario()}
            >
              Adicionar ao calendario
              <BsGoogle size={18} />
          </button>*/}
          </div>{" "}
          <MinhaAgenda />
          <ModalPermissaoGoogle
            visible={modalPermissaoGoogleVisible}
            onDismiss={() => setModalPermissaoGoogleVisible(false)}
          />
          <ButtonZap link={"https://wa.me/5511949322544"} />
        </div>
      </div>
      <img
        src={bgAluno1}
        alt="aluno-section1-bg"
        className="aluno-section1-bg"
      />
    </div>
  );
};

// TODO: ajeitar prog bar, parametrizar as coisas
const CardCurso = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="aluno-section1-card"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="aluno-section1-card-up">
        <Link
          className={
            isHover ? "aluno-button-continuar" : "aluno-button-continuar-off"
          }
          to={generatePath(ROUTES.classes, { slugCurso: "mtst" })}
        >
          Iniciar curso
        </Link>
      </div>
      <div className="aluno-section1-card-down">
        <div className="aluno-section1-progbar"></div>
        <text className="aluno-section1-card-text">meu progresso</text>
        <div></div>
        <text className="aluno-section1-card-text">
          {" "}
          Agora se inscreva no módulo II!{" "}
        </text>
      </div>
    </div>
  );
};

const CardCarrossel = ({ idCurso, imagemCard }) => {
  return (
    <div className="aluno-section3-card" key={idCurso}>
      <img src={imagemCard} className="aluno-section3-card-img" key={idCurso} />
      <Link
        className="aluno-section3-card-button"
        to={generatePath(ROUTES.course, { slugCurso: idCurso })}
      >
        Saiba Mais
      </Link>
    </div>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1301 },
    items: 4,
  },
  smallDesktop: {
    breakpoint: { max: 1300, min: 769 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

const setaEsq = () => {
  return <div className="aluno-cursos-modulo2-carrossel-seta-esq">Oi</div>;
};

const setaDir = () => {
  return <div className="aluno-cursos-modulo2-carrossel-seta-dir" />;
};

const CarrosselCursos = () => {
  return (
    <div className="aluno-cursos-modulo2">
      <div className="aluno-cursos-modulo2-titulos">
        <h1 className="Modulo-2">Módulo 2 - mão na massa!</h1>
        <h2>Conheça os cursos:</h2>
      </div>
      <div className="aluno-cursos-modulo2-carrossel-wrapper">
        <Carousel
          infinite
          autoPlay
          autoPlaySpeed={4000}
          keyBoardControl={true}
          responsive={responsive}
          draggable={false}
          itemClass="carousel-item-padding-32-px"
        >
          {Object.keys(cursosById)
            .filter((idCurso) => !!cursosById[idCurso].card)
            .map((idCurso) => {
              const curso = cursosById[idCurso];
              return (
                <CardCarrossel idCurso={idCurso} imagemCard={curso.card} />
              );
            })}
        </Carousel>
      </div>
    </div>
  );
};
