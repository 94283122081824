import { AcordeonAulas } from "./AcordeonAulas";
import bgAula1 from "../../../images/bg-aula1.png";

export const VisaoGeralCurso = ({
  aulas,
  children,
  titulo,
  subtitulo,
  onClickContinue,
  aulaIndicada,
}) => {
  const receberAula = (aula) => {
    aulaIndicada(aula);
  };

  return (
    <div className="aulas-inicial-container">
      <AcordeonAulas
        aulas={aulas}
        idAula={null}
        aulaDireto={onClickContinue}
        aulaEscolhida={receberAula}
      />

      <div className="aulas-passo-container">
        <div className="aulas-inicio-wrapper-curso">
          <h1 className="aulas-inicio-h1-curso">{titulo}</h1>
          <h2 className="aulas-inicio-h2-curso">{subtitulo}</h2>
          {children}
          {onClickContinue && (
            <button
              className="aulas-inicio-button-continuar"
              onClick={onClickContinue}
            >
              Iniciar Curso
            </button>
          )}
        </div>
        <img
          src={bgAula1}
          alt="aulas-inicio-img-bg1"
          className="aulas-inicio-img-bg1"
        />
      </div>
    </div>
  );
};
