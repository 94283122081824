import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetCursos } from "../queries/curso";
import { cursos } from "../pages/Curso/setoriais";

const getNomeCurso = (curso) => {
  return curso?.setorOuColetivo?.nome
    ? curso.setorOuColetivo.nome
    : curso.titulo;
};

const ListaCursos = ({ dropdownMode, fechar }) => {
  const { data } = useGetCursos();
  const [listaCursos, setListaCursos] = useState(cursos);

  useEffect(() => {
    if (!data?.data) return;
    setListaCursos([...data?.data, ...cursos]);
  }, [data]);

  return dropdownMode ? (
    <div className="header-dropdown-wrapper">
      <button className="header-dropdown-main">
        Cursos
        <svg
          width="19"
          height="12"
          viewBox="0 0 19 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.693 8.28454C9.11089 8.68407 9.7795 8.64704 10.1507 8.2038L16.0504 1.15931C16.1876 0.995515 16.3736 0.879959 16.5812 0.829577L16.9253 0.746085C17.8747 0.515715 18.5571 1.64349 17.9126 2.37763L10.1376 11.2338C9.7682 11.6545 9.12539 11.6901 8.7119 11.3126L0.51063 3.82613C0.203355 3.54563 0.101852 3.10419 0.255754 2.71765L0.428169 2.28462C0.690427 1.62594 1.53584 1.44179 2.04829 1.93173L8.693 8.28454Z"
            fill="#101010"
          />
        </svg>
      </button>
      <div className="header-dropdown-child">
        {listaCursos.map((curso, index) => (
          <Link
            key={index}
            className="header-dropdown-child-link"
            to={`/cursos/${curso.slug ? curso.slug : curso.id}`}
          >
            {getNomeCurso(curso)}
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <div className="sidebar-wrapper-bloco">
      <text className="sidebar-text-main">Cursos</text>
      {listaCursos.map((curso, index) => (
        <Link
          key={index}
          className="sidebar-text-child"
          to={`/cursos/${curso.slug ? curso.slug : curso.id}`}
          onClick={fechar}
        >
          {getNomeCurso(curso)}
        </Link>
      ))}
    </div>
  );
};

export { ListaCursos };
