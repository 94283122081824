import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { InputWithLabel } from "../../components/Input";

import icUser from "../../images/ic-user1.png";

import "../../styles/Acesso.css";

export const LoginForm = ({ onLoginPress, loginError, isLoading }) => {
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  return (
    <>
      <form onSubmit={handleSubmit(onLoginPress)} className="acesso-form">
        <InputWithLabel
          label={"E-mail"}
          type="email"
          {...register("email", { required: "E-mail é obrigatório" })}
          errors={errors.email}
        />
        <InputWithLabel
          type="password"
          label={"Senha"}
          {...register("password", { required: "Senha é obrigatória" })}
          errors={errors.password}
        />

        {loginError ? (
          <text className="acesso-link-label">
            Usuário não encontrado! Confira o e-mail e senha.
          </text>
        ) : null}

        <button
          type="submit"
          className="acesso-button acesso-button-primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <ClipLoader color={"#fff"} loading="true" height={15} margin={8} />
          ) : (
            <>
              <img
                src={icUser}
                alt="acesso-img-user"
                className="acesso-img-user"
              />
              ENTRAR
            </>
          )}
        </button>

        <button
          onClick={() => navigate("/esqueci-a-senha")}
          className="acesso-button acesso-button-secondary text-center"
        >
          Esqueceu sua senha?
        </button>
      </form>
    </>
  );
};
