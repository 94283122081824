import fundoHorta from "../../../images/cursos-setores/fundoHorta.png";
import imagemDireitaHorta from "../../../images/cursos-setores/imagemDireitaHorta.png";
import card from "../../../images/card-hor.png";

export const SobreCursoHorta = () => {
  return (
    <>
      Garantir a alimentação das pessoas deveria ser uma das maiores prioridades
      do governo. Entretanto, em um levantamento da rede PENSSAN em 2022, foi
      mostrado que mais da metade da população brasileira sofre de insegurança
      alimentar e, ainda mais, são 33,1 milhões de brasileiros passando fome no
      país. Isso assusta, né? O setor de horta foi criado para mostrar que é
      direito nosso ter a garantia da alimentação diária e não só isso: termos
      acesso a um alimento saudável, seguro e digno, a fim de, coletivamente,
      atingir a soberania alimentar.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O setor de Cozinhas Solidárias, Horta Urbana e Segurança Alimentar do MTST
      é composto por militantes dispostos a literalmente colocar o pé no barro,
      somando na construção das hortas, assim como na prática da escuta e troca
      de ideias com nosso povo. O setor não exige conhecimento prévio de
      plantio, mas disposição para se envolver e cuidar dos espaços junto com os
      nossos companheiros de luta. Nosso foco principal é a segurança e
      soberania alimentar, que é colocada em diversos territórios, dentre eles
      as ocupações e cozinhas solidárias.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const horta = {
  id: "horta",
  titulo: "Segurança alimentar é direto de todos",
  categoria: "Horta",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Plantar, colher e… mais o quê?",
      formador: null,
    },
    {
      titulo: "Cozinhas Solidárias e Segurança Alimentar na Periferia",
      formador: null,
    },
    {
      titulo: "Plantar, colher e lutar!",
      formador: null,
    },
    {
      titulo: "Defesa do meio ambiente para dentro das ocupações",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#2B8F59",
    corTitulo: "#FEFCFB",
    fundo: fundoHorta,
    itemDireita: imagemDireitaHorta,
  },
  materiais:
    "Luva de jardinagem, chapéu/boné, protetor solar, água, sapato fechado, ferramentas de horta, kit sem-teto: prato/vasilha, copo, talher.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Horta",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VIDEO+HORTA.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511994896925",
};
