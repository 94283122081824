export const ROUTES = {
  home: "/",
  activation: "/ativacao/:signupId",
  login: "/acesso",
  updatePassword: "/alterar-senha",
  esqueciASenha: "/esqueci-a-senha",
  recuperarSenha: "/recuperar-senha/:resetId",
  areaAluno: "/area-do-aluno",
  areaAluno2: "/areadoaluno",
  course: "/cursos/:slugCurso",
  classes: "/cursos/:slugCurso/aulas",
  contents: "/conteudos",
  profile: "/perfil",
  confirmarPresenca: "/confirmar-presenca/:idPasso",
};
