import { combineReducers } from "redux";

import { AuthReducer } from "./auth";
import { EscolaReducer } from "./escola";
import { UsuarioReducer } from "./usuario";
import { PerfilReducer } from "./perfil";

export const RootReducer = combineReducers({
  auth: AuthReducer,
  escola: EscolaReducer,
  usuario: UsuarioReducer,
  perfil: PerfilReducer,
});
