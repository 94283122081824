import { GoogleAuth } from "../../utils/googleAuth";
import { addSeconds } from "date-fns";

import { Modal } from "../Modal";
import "./ModalPermissaoGoogle.css";
import { CalendarioService } from "../../services/CalendarioService";

export const ModalPermissaoGoogle = ({ visible, onDismiss }) => {
  const googleAuth = new GoogleAuth();

  function handleAuth() {
    googleAuth
      .askPermission()
      .then(async (permissionResponse) => {
        googleAuth.setGoogleCalendarToken(permissionResponse.access_token);
        googleAuth.setGoogleCalendarTokenExpiration(
          addSeconds(new Date(), permissionResponse.expires_in).toString()
        );

        const idCurso = "65388657ef38f98729e84172"; // TODO - obter o idcurso para enviar
        const calendarioResponse =
          await CalendarioService.salvarCalendarioCurso(
            idCurso,
            permissionResponse.access_token
          );
        console.log("calendarioRes", calendarioResponse);
        onDismiss();
      })
      .catch((err) => {
        console.log("erro", err);
        onDismiss();
      });
  }

  return (
    <Modal
      autoHeight={true}
      visible={visible}
      onDismiss={onDismiss}
      showCloseButton
    >
      <div className="modal-body">
        <h2>CALENDARIO GOOGLE</h2>

        <p>
          Não detectamos permissão para registrar os eventos no calendário da
          sua conta google!
        </p>

        <p>
          Caso você já tenha dado permissão, seu acesso pode ter expirado e
          necessita ser renovado.
        </p>

        <button className="button-permitir-acesso" onClick={() => handleAuth()}>
          PERMITIR ACESSO!
        </button>
      </div>
    </Modal>
  );
};
