export const AulasMaterial = ({ url, formato }) => {
  if (formato === "pdf") {
    return <PDFEmbed url={url} />;
  }
};

const PDFEmbed = ({ url }) => {
  return (
    <div className="aulas-material-wrapper">
      <iframe src={url} width="640" height="480" allow="autoplay" />
    </div>
  );
};
