import { Input, Dropdown } from "../../../../components";
import { VALIDATION_RULES_BY_FIELD } from "../../../../utils/formValidation";

export const OPCOES_PRONOME = [
  "Ela / dela / a",
  "Ele / dele / o",
  "Elu / delu / e ou u",
];

const InfoPessoal = ({
  nomeAluno,
  setNomeAluno,
  numeroMatricula,
  setNumeroMatricula,
  dataNascimento,
  setDataNascimento,
  pronomeSelecionado,
  setPronomeSelecionado,
  cep,
  setCep,
  numero,
  setNumero,
  complemento,
  setComplemento,
  ocupacaoAtual,
  setOcupacaoAtual,
  organizacao,
  setOrganizacao,
}) => {
  return (
    <>
      <text className="curso-wrapper-text">
        Vamos completar o seu perfil para a inscrição dar certo! 🎉
      </text>
      <div className="perfil-inputs-container">
        <br></br>
        <div className="nome-input-wrapper">
          <div className="perfil-wrapper-label">
            <br></br>
            <text className="perfil-text-label">Nome</text>
          </div>
          <Input
            value={nomeAluno}
            onChange={setNomeAluno}
            validationRules={VALIDATION_RULES_BY_FIELD.nome}
            errorsVisible
          />
        </div>
        <div className="matricula-input-wrapper">
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">Número de Matrícula</text>
          </div>
          <Input
            value={numeroMatricula}
            onChange={setNumeroMatricula}
            readonly
          />
        </div>
        <div>
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">Data de nascimento</text>
          </div>
          <Input
            value={dataNascimento}
            onChange={setDataNascimento}
            placeholder="dd/mm/aaaa"
            maskOptions={{
              options: { date: true, datePattern: ["d", "m", "Y"] },
            }}
          />
        </div>
        <div>
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">Qual seu pronome?</text>
          </div>
          <Dropdown
            opcoes={OPCOES_PRONOME}
            valor={pronomeSelecionado}
            onChange={setPronomeSelecionado}
          />
        </div>
        <div>
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">CEP</text>
          </div>
          <Input
            value={cep}
            onChange={setCep}
            validationRules={VALIDATION_RULES_BY_FIELD.nome}
            errorsVisible
            placeholder="00000-000"
            maskOptions={{
              options: {
                blocks: [5, 3],
                delimiter: "-",
                numericOnly: true,
              },
            }}
          />
        </div>
        <div>
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">Número</text>
          </div>
          <Input
            value={numero}
            onChange={setNumero}
            validationRules={VALIDATION_RULES_BY_FIELD.nome}
            errorsVisible
          />
        </div>
        <div>
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">Complemento</text>
          </div>
          <Input
            value={complemento}
            onChange={setComplemento}
            validationRules={VALIDATION_RULES_BY_FIELD.nome}
            errorsVisible
          />
        </div>
        <div className="ocupacao-input-wrapper">
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">
              Qual a sua formação profissional?
            </text>
          </div>
          <Input value={ocupacaoAtual} onChange={setOcupacaoAtual} />
        </div>
        <div className="org-input-wrapper">
          <div className="perfil-wrapper-label">
            <text className="perfil-text-label">
              Faz ou já fez parte de alguma organização ou movimento? Qual?
            </text>
          </div>
          <Input value={organizacao} onChange={setOrganizacao} />
        </div>
      </div>
    </>
  );
};

export default InfoPessoal;
