const accessToken = JSON.parse(localStorage.getItem("accessToken"));

const initialState = {
  accessToken,
  isLogged: !!accessToken,
  error: false,
  errorMessage: null,
};

export const AuthReducer = function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        accessToken: payload.accessToken,
        isLogged: true,
        error: false,
        errorMessage: null,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        userCreated: true,
        accessToken: payload.accessToken,
        signupVerified: false,
        isLogged: true,
        error: false,
        errorMessage: null,
      };
    case "SIGNUP_FAIL":
      return {
        ...state,
        isLogged: false,
        userCreated: false,
        error: true,
        errorMessage: payload || "Ocorreu um erro inesperado",
      };
    case "VERIFY_SUCCESS":
      return {
        ...state,
        signupVerified: true,
        error: false,
        errorMessage: null,
      };
    case "VERIFY_FAIL":
      return {
        ...state,
        error: true,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        isLogged: false,
        error: true,
      };
    case "LOGOUT":
      return {
        ...state,
        accessToken: null,
        isLogged: false,
        error: false,
        errorMessage: null,
      };
    case "REFRESH_TOKEN":
      return {
        ...state,
        accessToken: payload,
        error: false,
      };
    default:
      return state;
  }
};
