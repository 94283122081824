import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../../store/actions/auth";

import { LoginForm } from "./LoginForm";

export const LoginContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const dispatch = useDispatch();
  const error = useSelector((store) => store.auth.error);

  useEffect(() => {
    if (error) {
      setLoginError(true);
    } else {
      setLoginError(false);
    }
    setIsLoading(false);
  }, [error]);

  const onLoginPress = (userData) => {
    const { email, password } = userData;
    setIsLoading(true);
    dispatch(login(email.trim(), password));
  };

  return (
    <LoginForm
      onLoginPress={onLoginPress}
      loginError={loginError}
      isLoading={isLoading}
    />
  );
};
