import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FadeSpinner, Header, InputWithLabel } from "../components";
import "../styles/EsqueciASenha.css";

import { UserService } from "../services/usuario";

import hlAtivacao from "../images/hl-rabisco3.png";

// Estrutura da Página:
export const EsqueciASenha = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState({ type: "", message: "" });

  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
  } = useForm();

  async function enviarSolicitacaoNovaSenha() {
    setIsLoading(true);

    UserService.askNewPassword(getValues("email"))
      .then((response) => {
        setIsLoading(false);
        setFeedback({ ...feedback, message: response.data.message });
      })
      .catch(() => {
        setIsLoading(false);
        setFeedback({
          ...feedback,
          message: "Erro ao criar sua solicitação. Tente novamente mais tarde!",
        });
      });
  }

  return (
    <div className="esqueci-a-senha-container">
      <div className="esqueci-a-senha-content">
        <Header />
        <div className="esqueci-a-senha-section">
          <div className="esqueci-a-senha-wrapper">
            <div className="esqueci-a-senha-wrapper-header">
              <h1 className="esqueci-a-senha-h1-header">Esqueci a Senha</h1>
              <img
                src={hlAtivacao}
                alt="esqueci-a-senha-img-header"
                className="esqueci-a-senha-img-header"
              />
            </div>

            <form
              onSubmit={handleSubmit(enviarSolicitacaoNovaSenha)}
              className="acesso-form esqueci-a-senha-form-margin"
            >
              <InputWithLabel
                label={"E-mail"}
                type="email"
                {...register("email", { required: "E-mail é obrigatório" })}
                errors={errors.email}
              />
              {isLoading && (
                <div className="esqueci-a-senha-row">
                  <FadeSpinner color="#000" size={20} />
                </div>
              )}
              {!isLoading && feedback.message === "" && (
                <button
                  type="submit"
                  className="acesso-button acesso-button-primary text-center"
                >
                  Solicitar nova senha
                </button>
              )}
              {!isLoading && feedback.message !== "" && (
                <p
                  className={`esqueci-a-senha-feedback esqueci-a-senha-feedback-${feedback.type}`}
                >
                  {feedback.message}
                </p>
              )}
              <button
                onClick={() => navigate("/acesso")}
                type="button"
                className="acesso-button acesso-button-secondary text-center"
              >
                Voltar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
