import { apiV1WithToken } from "./api-v1";

const PATH = "/calendario";

class CalendarioService {
  static async salvarCalendarioCurso(idCurso, calendarAccessToken) {
    const addEventResponse = await apiV1WithToken.post(`${PATH}/salvar-curso`, {
      idCurso,
      calendarAccessToken,
    });
    return addEventResponse;
  }
}

export { CalendarioService };
