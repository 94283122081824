import { forwardRef, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import "./input.css";

const InputPasswordWithLabel = forwardRef((props, ref) => {
  const [inputType, setInputType] = useState("password");
  const { label, errors } = props;

  function toggleInputType(e) {
    e.preventDefault();
    setInputType(inputType === "password" ? "text" : "password");
  }

  return (
    <div className="flex">
      <div className="input-password-with-label-container">
        <div className="input-wrapper-label">
          <label className="input-text-label">{label}</label>
        </div>
        <input
          className="input-with-label input-password-with-label"
          {...props}
          type={inputType}
          ref={ref}
        />
        {errors?.message && (
          <small className="input-error">* {errors.message}</small>
        )}
      </div>
      <button
        type="button"
        className="input-password-with-label-toggle"
        onClick={(e) => toggleInputType(e)}
      >
        {inputType === "password" ? (
          <BsEye size={18} />
        ) : (
          <BsEyeSlash size={18} />
        )}
      </button>
    </div>
  );
});

export { InputPasswordWithLabel };
