import { useQuery } from "@tanstack/react-query";

import { apiV1 } from "../services/api-v1";
import { apiV1WithToken } from "../services/api-v1";

const getCursos = async () => {
  return await apiV1.get("/courses/");
};

const getBySlug = async (slugCurso) => {
  return await apiV1WithToken.get(`/courses/slug/${slugCurso}`);
};

export const useGetCursos = () => {
  return useQuery({
    queryKey: ["cursos"],
    queryFn: getCursos,
  });
};

export const useGetCursoBySlug = (slugCurso) => {
  return useQuery({
    queryKey: ["cursos", slugCurso],
    queryFn: () => getBySlug(slugCurso),
    enabled: !!slugCurso,
  });
};
