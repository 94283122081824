import icAula2 from "../../../images/ic-back.png";
import icAula3 from "../../../images/ic-next.png";

// Importar Imagens:
export const BotoesAnteriorProx = ({ onClickBack, onClickNext }) => {
  const nextClassName = onClickNext
    ? "aulas-button-next-prev"
    : "aulas-tarefa-button-next-off";

  return (
    <div className="aulas-wrapper-btn">
      <button className="aulas-button-next-prev" onClick={onClickBack}>
        <img
          src={icAula2}
          alt="aulas-video-img-seta"
          className="aulas-video-img-seta"
        />
        Anterior
      </button>
      {onClickNext ? (
        <button className={nextClassName} onClick={onClickNext}>
          Próxima
          <img
            src={icAula3}
            alt="aulas-video-img-seta"
            className="aulas-video-img-seta"
          />
        </button>
      ) : null}
    </div>
  );
};
