import React from "react";
import { Outlet, Navigate } from "react-router";
import { useSelector } from "react-redux";
import { ROUTES } from "../routing";

const Protected = () => {
  const isLogged = useSelector((store) => store.auth.isLogged);

  return isLogged ? <Outlet /> : <Navigate to={ROUTES.login} />;
};

export default Protected;
