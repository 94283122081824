import axios from "axios";
import { getLocalAccessToken } from "./token";

const BASE_URL = "https://etb-api-prod.herokuapp.com";

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiWithToken = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getLocalAccessToken()}`,
  },
});
