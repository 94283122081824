// Regex
const hasMinLengthRegex = new RegExp("^(?=.{8,})");
const hasSpecialCharacterRegex = new RegExp("^(?=.*[!@$%*?])");
const hasUpperCaseLetterRegex = new RegExp("^(?=.*[A-Z])");
const hasLowerCaseLetterRegex = new RegExp("^(?=.*[a-z])");
const hasNumberRegex = new RegExp("^(?=.*[0-9])");
const hasOnlyValidCharacters = new RegExp("^[A-Za-z0-9!@$%*?]*$");
const isEmail = new RegExp("S+@S+.S+");

// Funções de validação

// Validação de senha
export const minLengthRule = (value) =>
  value.length !== 0 ? hasMinLengthRegex.test(value) : null;

export const specialCharacterRule = (value) =>
  value.length > 0 &&
  hasSpecialCharacterRegex.test(value) &&
  hasOnlyValidCharacters.test(value);

export const upperCaseRule = (value) =>
  value.length > 0 && hasUpperCaseLetterRegex.test(value);

export const lowerCaseRule = (value) =>
  value.length > 0 && hasLowerCaseLetterRegex.test(value);

export const hasNumberRule = (value) =>
  value.length > 0 && hasNumberRegex.test(value);

export const confirmPasswordRule = (password, confirmPassword) =>
  password.length > 0 &&
  confirmPassword.length > 0 &&
  password === confirmPassword;

export const allValidValuesRules = (rules) => {
  const values = Object.values(rules);
  return values.every((value) => value);
};

// Validação de email
export const emailRules = (email) => email.length > 0 && isEmail.test(email);
