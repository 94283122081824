import { api } from "./api";
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
} from "./token";
import { refreshToken } from "../store/actions/auth";

const setup = (store) => {
  api.interceptors.request.use(
    (config) => {
      if (config.headers && config.headers["Authorization"]) {
        return config;
      }

      const token = getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig && originalConfig.url !== "/token" && err.response) {
        // O Access Token expirou
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const localRefreshToken = getLocalRefreshToken();
          try {
            const rs = await api.post(
              "/token/refresh",
              {},
              {
                headers: {
                  Authorization: "Bearer " + localRefreshToken,
                },
              }
            );

            const newAccessToken = rs.data.access_token;
            const newRefreshToken = rs.data.refresh_token;
            console.warn(newAccessToken, newRefreshToken);

            dispatch(refreshToken(newAccessToken));
            updateLocalAccessToken(newAccessToken);
            updateLocalRefreshToken(newRefreshToken);

            return api(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
