import fundoCultura from "../../../images/cursos-setores/fundoCultura.png";
import imagemDireitaCultura from "../../../images/cursos-setores/imagemDireitaCultura.png";
import card from "../../../images/card-art.png";

export const SobreCursoCultura = () => {
  return (
    <>
      Nesse curso serão apresentados os principais fundamentos que pautam a
      organização do Setor de Arte e Cultura do MTST. Realizaremos, em 4 aulas,
      formações, debates e atividades práticas para que todos conheçam como o
      movimento usa as artes como instrumentos da luta e do trabalho de base.
      Discutiremos a importância da Mística e da simbologia na mobilização
      política, o elo inquebrável da cultura periférica com o MTST e, por fim,
      como o Setor organiza sua atuação a partir da criação coletiva nos
      territórios
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O Setor de Arte e Cultura tem como papel, fundamentalmente, a construção
      coletiva de uma arte-militante que esteja conectada com a luta do MTST.
      Entre suas diversas responsabilidades, o Setor atua com: simbologia visual
      e oral para os espaços do movimento e atos de rua; a Mística; atividades
      culturais nas ocupações, cozinhas e comunidades; o resgate da cultura
      hip-hop e periférica como parte da cultura sem-teto; o aprendizado e a
      denúncia social por meio da arte; e a construção conjunta e sensível das
      nossas utopias.
    </>
  );
};

export const cultura = {
  id: "cultura",
  titulo: "Arte e cultura popular do nosso chão",
  categoria: "Arte e cultura",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo:
        "A Mística e a cultura nos movimentos sociais: qual seu papel no MTST?",
      formador: null,
    },
    {
      titulo: "Simbologia e a luta social",
      formador: null,
    },
    {
      titulo: "Cultura e periferia: o que o MTST tem a ver com isso?",
      formador: null,
    },
    {
      titulo: "Como organizar a Cultura no território?",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#AC0033",
    corTitulo: "#FEFCFB",
    fundo: fundoCultura,
    itemDireita: imagemDireitaCultura,
  },
  materiais: "Caderno, caneta, garrafa d'água e um lanchinho.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Arte e cultura",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VI%CC%81DEO+CULTURA.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511985756677",
};
