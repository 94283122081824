import { useState, useEffect } from "react";

import { AulasPasso } from "./AulasPasso";
import { AcordeonAulas, BotoesAnteriorProx } from "./components";
import { set } from "date-fns";

export const AulasCursoControle = ({ aulas, voltarAoInicio, aulaCerta }) => {
  //harcoded
  var [aulaPasso, setAulaPasso] = useState({ aulaIndex: 7, passoIndex: 0 });

  useEffect(() => {
    if (aulaCerta !== null && aulaCerta !== undefined) {
      setAulaPasso({ aulaIndex: aulaCerta - 1, passoIndex: 1 });
    }
  }, [aulaCerta]);

  console.log("aulasCursoControle  :", aulaCerta, aulaPasso.aulaIndex);

  if (!aulas) return;
  const { aulaIndex, passoIndex } = aulaPasso;

  const voltar = () => {
    if (aulaIndex === 0 && passoIndex === 0) {
      voltarAoInicio();
    } else {
      if (passoIndex === 0) {
        const aulaAnterior = aulas[aulaIndex - 1];
        setAulaPasso({
          aulaIndex: aulaIndex - 1,
          passoIndex: aulaAnterior.passos.length - 1,
        });
      } else {
        setAulaPasso({ aulaIndex, passoIndex: passoIndex - 1 });
      }
    }
  };

  const aulaAtual = aulas[aulaIndex];
  const ultimoPasso =
    (aulaIndex === aulas.length - 1 ||
      aulas[aulaIndex + 1].passos.length === 0) &&
    passoIndex === aulaAtual.passos.length - 1;
  const avancar = () => {
    if (ultimoPasso) return;
    if (passoIndex === aulaAtual.passos.length - 1) {
      setAulaPasso({ aulaIndex: aulaIndex + 1, passoIndex: 0 });
    } else {
      setAulaPasso({ aulaIndex, passoIndex: passoIndex + 1 });
    }
  };

  const passo = aulaAtual.passos[passoIndex];

  return (
    <>
      <HeaderContent aula={aulaAtual} aulaIndex={aulaIndex} />
      <div className="aulas-passo-container">
        <AulasPasso passo={passo} />
        <BotoesAnteriorProx
          onClickBack={voltar}
          onClickNext={ultimoPasso ? null : avancar}
        />
      </div>
    </>
  );
};

const HeaderContent = ({ aula, aulaIndex }) => {
  return (
    <div className="aulas-passo-header">
      <AcordeonAulas aulas={[aula]} numInicial={aulaIndex + 1} />
      <div className="aulas-passo-header-container">
        <h2 className="titulo-da-aula">{aula?.titulo}</h2>
        <span className="subtitulo-da-aula">{aula?.subtitulo}</span>
      </div>
    </div>
  );
};
