import { apiV1WithToken } from "./api-v1";

const PERFIL_PATH = "/perfil";

const buscarFormularioPerfil = (userId) => {
  return apiV1WithToken.get(`${PERFIL_PATH}/${userId}`).then((response) => {
    const data = response.data;

    const redesSociais = [
      "Instagram",
      "Facebook",
      "Twitter",
      "Twitch TV",
      "YouTube",
      "Linkedin",
    ];

    const redesSociaisObj = redesSociais
      .map((rede) => {
        const nomeUsuario = data[rede.toLowerCase().replace(" ", "")];
        if (nomeUsuario) {
          return {
            id: crypto.randomUUID(),
            tipoRedeSocial: rede,
            nomeUsuario: nomeUsuario,
          };
        }
        return null;
      })
      .filter((rede) => rede !== null);

    if (!redesSociaisObj.length) {
      redesSociaisObj.push({
        id: crypto.randomUUID(),
        tipoRedeSocial: "",
        nomeUsuario: "",
      });
    }

    return {
      nomeAluno: data.name,
      numeroMatricula: data.signup_id,
      dataNascimento: new Date(data.birthdate).toLocaleDateString("pt-br", {
        timeZone: "UTC",
      }),
      pronomes: data.pronoums,
      cep: data.cep,
      numeroEndereco: data.address_number,
      complementoEndereco: data.address_complement,
      ocupacaoAtual: data.current_occupation,
      organizacao: data.organization,
      areasInteresse: data.interest_areas ?? [],
      redesSociais: redesSociaisObj,
    };
  });
};

const enviarPerfil = (props) => {
  const redes = props.redesSociais.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.tipoRedeSocial.toLowerCase().replace(" ", "")]: cur.nomeUsuario,
    }),
    {}
  );
  const [dia, mes, ano] = props.dataNascimento.split("/");
  const dataNascimentoISO = props.dataNascimento
    ? new Date(ano, mes - 1, dia).toISOString()
    : null;
  const cepSemPontuacao = props.cep.replace(/[./_-]/g, "");

  const req_payload = {
    name: props.nomeAluno,
    birthdate: dataNascimentoISO,
    pronoums: props.pronomeSelecionado,
    cep: cepSemPontuacao,
    address_number: props.numeroEndereco,
    address_complement: props.complementoEndereco,
    current_occupation: props.ocupacaoAtual,
    organization: props.organizacao,
    interest_areas: props.areasInteresseSelecionadas,
    ...redes,
  };

  return apiV1WithToken
    .put(PERFIL_PATH, req_payload)
    .then((response) => response.data);
};

export const PerfilService = {
  buscarFormularioPerfil,
  enviarPerfil,
};
