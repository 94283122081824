import fundoComunicacao from "../../../images/cursos-setores/fundoComunicacao.png";
import imagemDireitaComunicacao from "../../../images/cursos-setores/imagemDireitaComunicacao.png";
import card from "../../../images/card-com.png";

export const SobreCursoComunicacao = () => {
  return (
    <>
      O curso do setor de comunicação visa entender o potencial estratégico da
      comunicação na disputa política. Como expor as nossas lutas e ideias para
      a sociedade. Como consolidar os nossos princípios no imaginário popular e
      na política institucional. Como conquistar novos corações e criar
      consciência política nas pessoas. Como comunicar com diferentes grupos
      sociais, sem jamais perder o nosso conteúdo durante o processo.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O setor de comunicação é o megafone do MTST. É o setor responsável por
      apresentar a luta, os ideais e a própria cara do MTST para a sociedade,
      assim como comunicar de forma orgânica com a nossa própria base de
      militantes. Fazemos disputa política dentro das redes e criamos uma
      alternativa de comunicação popular contra a mídia hegemônica e burguesa.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const comunicacao = {
  id: "comunicacao",
  titulo: "Quem não pode com a formiga, não atiça o formigueiro",
  categoria: "Comunicacao",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Processo histórico",
      formador: null,
    },
    {
      titulo: "Comunicação como ferramenta de disputa política",
      formador: null,
    },
    {
      titulo: "Públicos: comunicação de redes e comunicação dos territórios",
      formador: null,
    },
    {
      titulo: "A comunicação do MTST e os desafios para o futuro",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#CC1F1F",
    corTitulo: "#FEFCFB",
    fundo: fundoComunicacao,
    itemDireita: imagemDireitaComunicacao,
  },
  materiais: "Material para anotação",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Comunicação",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VIDEO+COMUNICA%C3%87%C3%83O.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511981373770",
};
