import fundoTecnologia from "../../../images/cursos-setores/fundoTecnologia.png";
import imagemDireitaTecnologia from "../../../images/cursos-setores/imagemDireitaTecnologia.png";
import card from "../../../images/card-tec.png";

export const SobreCursoTecnologia = () => {
  return (
    <>
      Este será um brevíssimo curso que procura apresentar como se organiza e
      com que diretrizes trabalha o Núcleo de Tecnologia do MTST, um dos setores
      da nossa militância. Como o prazo será curto, propomos uma passagem rápida
      por algumas das frentes em que atuamos presentemente.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O Núcleo de Tecnologia procura oferecer alternativas populares em relação
      às tecnologias – muitas vezes alienantes – que gerenciam a vida do
      trabalhador brasileiro. Para tanto, grosso modo, o grupo trabalha nas
      frentes a seguir: <br></br>
      <br></br>
      -Frente de projetos: realiza o desenvolvimento de projetos que facilitem a
      organização e a convivência da nossa base; <br></br>
      <br></br>
      -Frente de cursos: conduz cursos profissionalizantes que tornem o manejo
      das técnicas que permeiam nossos cotidianos menos alienantes. <br></br>
      <br></br>
      Não é necessário ter experiência prévia com programação nem com trabalho
      no setor de tecnologia e informática para ingressar no setor.{" "}
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const tecnologia = {
  id: "tecnologia",
  titulo: "Programar, programar, o poder popular",
  categoria: "Tecnologia",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Porque um movimento de moradia tem um setor de tecnologia?",
      formador: null,
    },
    {
      titulo:
        "Como o MTST utiliza, na prática, a tecnologia para auxiliar na luta por moradia?",
      formador: null,
    },
    {
      titulo: "Tecnologia e educação, como difundir o conhecimento técnico?",
      formador: null,
    },
    {
      titulo: "Capitalismo de plataforma e organização de classe",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#DD3B80",
    corTitulo: "#FEFCFB",
    fundo: fundoTecnologia,
    itemDireita: imagemDireitaTecnologia,
  },
  materiais:
    "Caso você seja de São Paulo acompanhará a versão presencial do curso, deve se preparar pra atividades nas ocupações. Caso você seja de outros estados, prepare café para a VERSÃO ONLINE.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Tecnologia",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VIDEO+TECNOLOGIA.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511993872555",
};
