import "react-international-phone/style.css";
import { usePhoneInput, CountrySelector } from "react-international-phone";
import { forwardRef } from "react";

const InputPhoneWithLabel = forwardRef((props, ref) => {
  const { label, errors } = props;

  const { country, setCountry } = usePhoneInput({
    defaultCountry: "br",
  });

  return (
    <div className="flex">
      <CountrySelector
        selectedCountry={country}
        onSelect={(e) => setCountry(e.iso2)}
        className="input-phone-country-selector"
      />

      <div className="input-phone-with-label-container">
        <div className="input-wrapper-label">
          <label className="input-text-label">{label}</label>
        </div>
        <input
          className="input-with-label input-phone-with-label"
          ref={ref}
          {...props}
        />
        {errors?.message && (
          <small className="input-error">* {errors.message}</small>
        )}
      </div>
    </div>
  );
});

export { InputPhoneWithLabel };
