import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { verify } from "../store/actions/auth";

import { Header } from "../components";
import { FadeSpinner } from "../components/FadeSpinner";

import "../styles/AtivacaoCadastro.css";

export const AtivacaoCadastro = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { signupId } = useParams();
  const dispatch = useDispatch();

  const signupVerified = useSelector((store) => store.auth.signupVerified);
  const error = useSelector((store) => store.auth.error);

  useEffect(() => {
    dispatch(verify(signupId));
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [signupVerified, error]);

  return (
    <>
      <div className="ativacao-cadastro-container">
        <div className="ativacao-cadastro-content">
          <Header />
          {isLoading && (
            <div className="ativacao-cadastro-spinner-section">
              <FadeSpinner className="ativacao-cadastro-spinner" />
            </div>
          )}
          {!isLoading && signupVerified && (
            <section className="ativacao-cadastro-section">
              <h1 className="ativacao-cadastro-h1">OBRIGADO!</h1>
              <text className="ativacao-cadastro-text">
                Seu cadastro está ativado!
              </text>
            </section>
          )}
          {!isLoading && !signupVerified && error && (
            <div className="ativacao-cadastro-section">
              <h1 className="ativacao-cadastro-h1">OPS, algo deu errado!</h1>
              <text className="ativacao-cadastro-text">
                Por favor, tente novamente em alguns instantes.
              </text>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
