import ClipLoader from "react-spinners/ClipLoader";

import { useBuscaInformacoesBrigadas } from "../../../queries/matricula";
import YoutubeEmbed from "../../../services/YoutubeEmbed.js";
import { ButtonZap } from "../../../components";

import icAula1 from "../../../images/ic-salvo.png";

export const AulasVideo = ({ videoId, tipo, locais, finalizado = false }) => {
  const { data, isLoading } = useBuscaInformacoesBrigadas();

  if (finalizado) {
    return (
      <>
        <h2>Prazo encerrado!</h2>
        <p style={{ fontSize: 14 }}>
          Essa aula em vídeo não está mais disponível.
        </p>
      </>
    );
  }

  return isLoading ? (
    <ClipLoader
      color={"#dd2626"}
      loading="true"
      size={60}
      height={30}
      margin={8}
    />
  ) : (
    <BloqueioPresencial
      cidade={data && data[0] ? data[0]?.cidade : null}
      cidadesPresenciais={locais}
      liberadoPresencial={tipo !== "video-e-presencial"}
    >
      <div className="aulas-video-wrapper-player">
        <YoutubeEmbed width={400} height={233} embedId={videoId} />
      </div>
    </BloqueioPresencial>
  );
};

const BloqueioPresencial = ({
  children,
  cidade,
  cidadesPresenciais,
  liberadoPresencial,
}) => {
  if (
    !!cidade &&
    !!cidadesPresenciais &&
    cidadesPresenciais.length > 0 &&
    !liberadoPresencial &&
    cidadesPresenciais.includes(cidade)
  ) {
    return (
      <>
        <h2>Sua aula é presencial!</h2>
        <p style={{ fontSize: 14 }}>
          Verifique mais informações na nossa comunidade de Whatsapp.
        </p>
        <ButtonZap
          link={"https://chat.whatsapp.com/DuBBpfkAjRb6YrDFMHgV2D"}
          message="Entrar na comunidade!"
        />
      </>
    );
  }
  return <>{children}</>;
};
