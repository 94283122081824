import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../store/actions/auth";
import { HashLink } from "react-router-hash-link";
import { ROUTES } from "../../routing";
import { primeiraLetraMaiuscula } from "../../utils/stringManipulation";
import { ListaCursos } from "../ListaCursos";

import { Sidebar } from "./Sidebar";

import icHome from "../../images/ic-etb1.png";
import icUser from "../../images/ic-user1.png";
import icMobile from "../../images/ic-mobile.png";

import "./Header.css";

export const Header = ({ showButton = true }) => {
  const isLogged = useSelector((store) => store.auth.isLogged);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [ancoras, setAncoras] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const paginaAreaDoAluno = location.pathname === ROUTES.areaAluno;

  const handleLogout = () => {
    dispatch(logout());
    window.location.reload(false);
    navigate(ROUTES.home);
  };

  const showSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  function gerarLinksAncoreAreaDoAluno() {
    return (
      <>
        {ancoras &&
          ancoras.map((ancora, index) => (
            <Link
              key={index}
              className="header-dropdown-child-link"
              to={`#${ancora.getAttribute("id")}`}
              onClick={(e) => {
                e.preventDefault();
                ancora.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
            >
              {primeiraLetraMaiuscula(ancora.innerHTML)}
            </Link>
          ))}
      </>
    );
  }

  useEffect(() => {
    setAncoras([
      ...document.querySelectorAll(".aluno-section1-h1-titulo, .Modulo-2"),
    ]);
  }, []);

  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="header-wrapper-logo">
          <Link to={ROUTES.home}>
            <img
              src={icHome}
              alt="header-img-home"
              className="header-img-home"
            />
          </Link>
        </div>
        <div className="header-wrapper-links">
          {/* CURSOS */}
          <ListaCursos dropdownMode />
          {/* CONTEÚDOS */}
          <div className="header-dropdown-wrapper">
            <button className="header-dropdown-main">
              Conteúdos
              <svg
                width="19"
                height="12"
                viewBox="0 0 19 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.693 8.28454C9.11089 8.68407 9.7795 8.64704 10.1507 8.2038L16.0504 1.15931C16.1876 0.995515 16.3736 0.879959 16.5812 0.829577L16.9253 0.746085C17.8747 0.515715 18.5571 1.64349 17.9126 2.37763L10.1376 11.2338C9.7682 11.6545 9.12539 11.6901 8.7119 11.3126L0.51063 3.82613C0.203355 3.54563 0.101852 3.10419 0.255754 2.71765L0.428169 2.28462C0.690427 1.62594 1.53584 1.44179 2.04829 1.93173L8.693 8.28454Z"
                  fill="#101010"
                />
              </svg>
            </button>
            <div className="header-dropdown-child">
              <Link className="header-dropdown-child-link" to={ROUTES.contents}>
                Hoje na Luta
              </Link>
            </div>
          </div>
          {/* QUEM SOMOS */}
          <HashLink className="header-link-static" to="/#quem-somos">
            Quem Somos
          </HashLink>
          {/* APOIE */}
          <HashLink className="header-link-static" to="/#apoie">
            Apoie!
          </HashLink>
          {/* DÚVIDAS */}
          <HashLink className="header-link-static" to="/#faq">
            Dúvidas
          </HashLink>

          {/* MINHA ÁREA */}
          {isLogged && (
            <div className="header-dropdown-wrapper">
              <button className="header-dropdown-main">
                Minha Área
                <svg
                  width="19"
                  height="12"
                  viewBox="0 0 19 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.693 8.28454C9.11089 8.68407 9.7795 8.64704 10.1507 8.2038L16.0504 1.15931C16.1876 0.995515 16.3736 0.879959 16.5812 0.829577L16.9253 0.746085C17.8747 0.515715 18.5571 1.64349 17.9126 2.37763L10.1376 11.2338C9.7682 11.6545 9.12539 11.6901 8.7119 11.3126L0.51063 3.82613C0.203355 3.54563 0.101852 3.10419 0.255754 2.71765L0.428169 2.28462C0.690427 1.62594 1.53584 1.44179 2.04829 1.93173L8.693 8.28454Z"
                    fill="#101010"
                  />
                </svg>
              </button>
              <div className="header-dropdown-child">
                <Link
                  className="header-dropdown-child-link"
                  to={ROUTES.areaAluno}
                >
                  Área do Aluno
                </Link>
                {paginaAreaDoAluno && gerarLinksAncoreAreaDoAluno()}
              </div>
            </div>
          )}
        </div>

        {/* ÁREA DE LOGIN */}

        {showButton ? (
          <HeaderLoginButton isLogged={isLogged} handleLogout={handleLogout} />
        ) : null}

        <div className="header-wrapper-mobile">
          <img
            src={icMobile}
            alt="header-img-mobile"
            className="header-img-mobile"
            onClick={showSidebar}
          />
          {sidebarOpen && <Sidebar active={setSidebarOpen} />}
          <div className="header-dropdown-mobile-ativo"></div>
        </div>
      </div>
    </div>
  );
};

const HeaderLoginButton = ({ isLogged, handleLogout }) => {
  return (
    <div className="header-wrapper-login">
      {isLogged ? (
        <>
          <Link className="header-link-static" onClick={handleLogout}>
            Sair
          </Link>
          <img src={icUser} alt="header-img-user" className="header-img-user" />
        </>
      ) : (
        <Link className="header-button-login" to={ROUTES.login}>
          <img
            src={icUser}
            alt="header-button-img-user"
            className="header-button-img-user"
          />
          ENTRAR
        </Link>
      )}
    </div>
  );
};
