import { Input } from "../../../../components";

const InfoContato = ({ email, setEmail, whatsapp, setWhatsapp }) => {
  const maskOptions = {
    options: {
      blocks: [0, 2, 5, 4],
      numericOnly: true,
      delimiter: " ",
      delimiters: ["(", ") ", "-"],
    },
  };

  return (
    <div className="contato-inputs-container">
      <div className="email-input-wrapper">
        <div className="perfil-wrapper-label">
          <text className="perfil-text-label">E-mail</text>
        </div>
        <Input value={email} onChange={setEmail} readonly />
      </div>
      <div className="whatsapp-input-wrapper">
        <div className="perfil-wrapper-label">
          <text className="perfil-text-label">Whatsapp</text>
        </div>
        <Input
          value={whatsapp}
          placeholder={"(00) 00000-0000"}
          onChange={setWhatsapp}
          readonly
          maskOptions={maskOptions}
        />
      </div>
    </div>
  );
};

export default InfoContato;
