import { animais, SobreCursoAnimais } from "./animais";
import { arquitetura, SobreCursoArquitetura } from "./arquitetura";
import { captacao, SobreCursoCaptacao } from "./captacao";
import { comunicacao, SobreCursoComunicacao } from "./comunicacao";
import { cultura, SobreCursoCultura } from "./cultura";
import { educacao, SobreCursoEducacao } from "./educacao";
import { formacao, SobreCursoFormacao } from "./formacao";
import { horta, SobreCursoHorta } from "./horta";
import { juridico, SobreCursoJuridico } from "./juridico";
import { juventude, SobreCursoJuventude } from "./juventude";
import { raiz, SobreCursoRaiz } from "./raiz";
import { saude, SobreCursoSaude } from "./saude";
import { tecnologia, SobreCursoTecnologia } from "./tecnologia";

//Remove lista dos cursos do menu com acesso externo
export const cursos = [
  /*  animais,
  arquitetura,
  captacao,
  cultura,
  comunicacao,
  educacao,
  formacao,
  horta,
  juridico,
  saude,
  tecnologia, */
];

export const cursosById = {
  [animais.id]: animais,
  [arquitetura.id]: arquitetura,
  [captacao.id]: captacao,
  [cultura.id]: cultura,
  [comunicacao.id]: comunicacao,
  [educacao.id]: educacao,
  [formacao.id]: formacao,
  [horta.id]: horta,
  [juridico.id]: juridico,
  [saude.id]: saude,
  [tecnologia.id]: tecnologia,
};

export const sobreByCursoId = {
  [animais.id]: SobreCursoAnimais,
  [arquitetura.id]: SobreCursoArquitetura,
  [captacao.id]: SobreCursoCaptacao,
  [cultura.id]: SobreCursoCultura,
  [comunicacao.id]: SobreCursoComunicacao,
  [educacao.id]: SobreCursoEducacao,
  [formacao.id]: SobreCursoFormacao,
  [horta.id]: SobreCursoHorta,
  [juridico.id]: SobreCursoJuridico,
  [saude.id]: SobreCursoSaude,
  [tecnologia.id]: SobreCursoTecnologia,
};
