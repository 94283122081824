import { PerfilService } from "../../services/perfil";

export const buscarFormularioPerfil = (userId) => (dispatch) => {
  return PerfilService.buscarFormularioPerfil(userId).then((data) => {
    dispatch({
      type: "PERFIL",
      payload: data,
    });
    return Promise.resolve();
  });
};

export const enviarPerfil = (props) => (dispatch) => {
  return PerfilService.enviarPerfil(props).then((data) => {
    dispatch({
      type: "PERFIL_ENVIADO",
      payload: data,
    });
    return Promise.resolve();
  });
};
