import fundoArquitetura from "../../../images/cursos-setores/fundoArquitetura.png";
import imagemDireitaArquitetura from "../../../images/cursos-setores/imagemDireitaArquitetura.png";
import card from "../../../images/card-arq.png";

export const SobreCursoArquitetura = () => {
  return (
    <>
      Nesse curso, vamos compartilhar um pouco das experiências do Setor de
      Arquitetura e Urbanismo do MTST. Esperamos, assim, poder debater sobre o
      papel do setor na luta por reforma urbana e compreender os limites e
      potencialidades de sua atuação nesse contexto.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O setor de arquitetura atua no suporte técnico, acompanhamento e
      desenvolvimento de projetos habitacionais, apoio nas intervenções de
      infraestrutura em espaços do movimento, buscando soluções alternativas de
      forma coletiva e participativa, e contribui com o debate sobre políticas
      habitacionais e sobre o direito à cidade, dentro e fora do movimento.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const arquitetura = {
  id: "arquitetura",
  titulo: "Do barraco ao concreto",
  categoria: "Arquitetura",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Cidades de lona: organização espacial das ocupações do MTST",
      formador: null,
    },
    {
      titulo: "Construção coletiva e processos participativos",
      formador: null,
    },
    {
      titulo: "Sustentabilidade e resistência",
      formador: null,
    },
    {
      titulo: "Arquitetura com política",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#343434",
    corTitulo: "#FEFCFB",
    fundo: fundoArquitetura,
    itemDireita: imagemDireitaArquitetura,
  },
  materiais: "Material para anotações.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Arquitetura",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VIDEO+ARQUITETURA.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511960478265",
};
