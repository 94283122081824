import axios from "axios";
import { getLocalAccessToken } from "./token";
import { AuthService, logout } from "./auth";

const BASE_URL = "https://etb-api.herokuapp.com/v1";
const REFRESH_PATH = "/users/login/refresh";

const apiV1 = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiV1WithToken = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiV1WithToken.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getLocalAccessToken()}`;
  return config;
});

apiV1WithToken.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.config && error.config?.url) {
      if (error.config.url === REFRESH_PATH) {
        logout();
        return Promise.reject(error);
      }
    }
    const data = error?.response?.data;
    if (data?.error === "Invalid token") {
      logout();
      return Promise.reject(error);
    }
    if (data?.error === "jwt expired") {
      if (!error.config?._isRetry) {
        error.config._isRetry = true;
        await AuthService.refreshToken();

        const newConfig = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${getLocalAccessToken()}`,
          },
        };

        const newResponse = await apiV1WithToken.request(newConfig);
        return newResponse;
      } else {
        logout();
      }
    }

    return Promise.reject(error);
  }
);

export { apiV1, apiV1WithToken };
