import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../routing";

import { AcessoPagina } from "./Acesso";

export const Acesso = () => {
  const navigate = useNavigate();

  const isLogged = useSelector((store) => store.auth.isLogged);

  useEffect(() => {
    if (isLogged) {
      navigate(ROUTES.areaAluno);
    }
  }, [isLogged]);

  return <AcessoPagina />;
};

export * from "./AcessoForm";
