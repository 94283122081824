import { apiV1WithToken } from "./api-v1";

const PATH = "/confirmacao";

class ConfirmacaoService {
  static async confirmarPresenca(idPasso) {
    try {
      const confirmacaoResponse = await apiV1WithToken.post(
        `${PATH}/${idPasso}`
      );
      return confirmacaoResponse;
    } catch (err) {
      return err.response;
    }
  }
}

export { ConfirmacaoService };
