// Importar Componentes:
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/auth";
import { ROUTES } from "../../../routing";
import "../../../components/Header/Sidebar.css";

// Importar Imagens:
import icClose from "../../../images/ic-fechar.png";

export const SidebarAula = ({ active }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeSidebar = () => {
    active(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.reload(false);
    navigate(ROUTES.home);
  };

  return (
    <div className="sidebar-container" sidebar={active}>
      <div className="sidebar-wrapper">
        <div className="sidebar-wrapper-bloco">
          <text className="sidebar-text-main">
            Curso Introdução ao Trabalho de Base no MTST
          </text>
        </div>
        <div className="sidebar-wrapper-bloco">
          <text className="sidebar-text-main">Minha Área</text>
          <Link className="sidebar-text-child" to={ROUTES.areaAluno}>
            Área do Aluno
          </Link>
        </div>
        <div className="sidebar-wrapper-bloco">
          <Link className="sidebar-text-main-link" onClick={handleLogout}>
            Sair
          </Link>
        </div>
        <div className="sidebar-wrapper-close">
          <img
            src={icClose}
            alt="sidebar-img-close"
            className="sidebar-img-close"
            onClick={closeSidebar}
          />
        </div>
      </div>
    </div>
  );
};
