import { Link, generatePath } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { ROUTES } from "../../routing";
import { useBuscaInformacoesBrigadas } from "../../queries/matricula";

import bgTerra from "../../images/bg-terra.png";
import bgCurso1 from "../../images/bg-aula1.png";

import "../../styles/Curso.css";

export const Banner = ({
  titulo,
  subtitulo = "",
  formadores,
  banner = {},
  status,
  onClickMatricular,
  slugCurso,
}) => {
  const cor = banner?.cor || "#C6714A";
  const fundo = banner?.fundo || bgTerra;
  const corTitulo = banner?.corTitulo || "#fefcfb";
  const corSubtitulo = banner?.corSubtitulo || "#101010";
  const itemDireita = banner?.itemDireita || bgCurso1;

  return (
    <div
      className="curso-banner-background"
      style={{ backgroundImage: `url(${fundo})` }}
    >
      <div
        className="curso-banner-overlay"
        style={{ backgroundColor: `${cor}B3` }}
      >
        <div className="curso-banner-esquerda">
          <div className="curso-banner-container-titulo">
            <h1 style={{ color: `${corTitulo}` }}>{titulo?.toUpperCase()}</h1>
            {subtitulo ? (
              <h2 style={{ color: `${corSubtitulo}` }}>
                {subtitulo.toUpperCase()}
              </h2>
            ) : null}
            {formadores && (
              <span className="curso-banner-formadores">{formadores}</span>
            )}
          </div>
          <AcaoCurso
            statusCurso={status}
            onClickMatricular={onClickMatricular}
            slugCurso={slugCurso}
          />
        </div>
        <div className="curso-banner-direita">
          {itemDireita ? (
            <img
              src={itemDireita}
              alt="Imagem do curso"
              width={410}
              className="curso-banner-img"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

/*
Status        Matriculado?  Texto
Não iniciado  Sim           Inscrição realizada!
Não iniciado  Não           Inscreva-se
Andamento     Sim           Continuar Curso
Andamento     Não           Inscrições encerradas
Encerrado     Sim           Inscrições encerradas
Encerrado     Não           Inscrições encerradas
*/
// const AcaoCurso = ({ statusCurso, onClickMatricular, slugCurso }) => {
//   const { data, isFetching } = useBuscaInformacoesBrigadas();

//   if (isFetching)
//     return (
//       // <div className="curso-button-matricula">
//       <ClipLoader color={"#fff"} loading="true" height={15} margin={8} />
//       // </div>
//     );

//   const estaMatriculado = !!(data && data?.length > 0);

//   if (
//     (statusCurso === "andamento" && !estaMatriculado) ||
//     statusCurso === "encerrado"
//   ) {
//     return (
//       <div className="curso-button-matricula">
//         <text>Inscrições Encerradas</text>
//       </div>
//     );
//   }
//   if (statusCurso === "andamento") {
//     return (
//       <Link
//         className="curso-button-matricula"
//         to={generatePath(ROUTES.classes, { slugCurso })}
//       >
//         Continuar curso
//       </Link>
//     );
//   }
//   if (estaMatriculado) {
//     return (
//       <Link className="curso-button-matricula" to={ROUTES.areaAluno}>
//         Inscrição realizada!
//       </Link>
//     );
//   }
//   return (
//     <button className="curso-button-matricula" onClick={onClickMatricular}>
//       INSCREVA-SE
//     </button>
//   );
// };

const AcaoCurso = ({ statusCurso, onClickMatricular, slugCurso }) => {
  if (statusCurso === "aguarde") {
    return (
      <div className="curso-button-matricula">
        <text>Inscrições em Breve</text>
      </div>
    );
  }
  if (statusCurso === "em andamento" || statusCurso === "encerrado") {
    return (
      <div className="curso-button-matricula">
        <text>Inscrições Encerradas</text>
      </div>
    );
  }
  return (
    <a
      className="curso-button-matricula"
      href="https://forms.gle/wnAZPoUz5dgFZ31C9"
      target="_blank"
    >
      INSCREVA-SE*
    </a>
  );
};
