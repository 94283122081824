import { useState } from "react";
import "../styles/Dropdown.css";

import icSeta from "../images/ic-seta1.png";

export const Dropdown = ({ opcoes, placeholder, valor, onChange }) => {
  const [mostrarOpcoes, setMostrarOpcoes] = useState(false);

  return (
    <div className="dropdown-container">
      <div
        className="dropdown-selecionado-container"
        onClick={() => setMostrarOpcoes((anterior) => !anterior)}
      >
        <text
          className={`dropdown-selecionado-text ${
            valor ? "" : "placeholder-text"
          }`}
        >
          {valor ? valor : placeholder}
        </text>
        <img className="dropdown-seta" src={icSeta} alt="seta" />
      </div>
      {mostrarOpcoes && (
        <div className="dropdown-opcoes-container">
          {opcoes.map((opcao) => (
            <text
              className="dropdown-opcoes-text"
              key={opcao}
              onClick={() => {
                onChange(opcao);
                setMostrarOpcoes(false);
              }}
            >
              {opcao}
            </text>
          ))}
        </div>
      )}
    </div>
  );
};
