import { forwardRef } from "react";

import "./input.css";
import { InputPasswordWithLabel } from "./InputPasswordWithLabel";
import { InputPhoneWithLabel } from "./InputPhoneWithLabel";

const InputWithLabelComponent = forwardRef((props, ref) => {
  const { label, errors, children, ...otherProps } = props;
  return (
    <div className="input-with-label-container">
      <div className="input-wrapper-label">
        <label className="input-text-label">{label}</label>
      </div>
      <input className="input-with-label" ref={ref} {...otherProps} />
      {errors?.message && (
        <small className="input-error">* {errors.message}</small>
      )}
    </div>
  );
});

export const InputWithLabel = forwardRef(function InputWithLabel(props, ref) {
  switch (props.type) {
    case "text":
      return <InputWithLabelComponent ref={ref} {...props} />;
    default:
      return <InputWithLabelComponent ref={ref} {...props} />;
    case "password":
      return <InputPasswordWithLabel ref={ref} {...props} />;
    case "tel":
      return <InputPhoneWithLabel ref={ref} {...props} />;
  }
});
