import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header, Input } from "../components";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import "../styles/Ativacao.css";

import { UserService } from "../services/usuario";

import hlAtivacao from "../images/hl-rabisco2.png";
import icUser from "../images/ic-user1.png";

// Importar validações de senha
import {
  minLengthRule,
  lowerCaseRule,
  upperCaseRule,
  specialCharacterRule,
  hasNumberRule,
  confirmPasswordRule,
  allValidValuesRules,
} from "../utils/validations";

import { passwordRulesMessages } from "../utils/passwordRules";

// Estrutura da Página:
export const RecuperarSenha = () => {
  const { resetId } = useParams();
  const navigate = useNavigate();

  const [novaSenha, setNovaSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [inputType, setInputType] = useState("password");

  const initialValidRules = {
    minLength: false,
    specialChar: false,
    upperCase: false,
    lowerCase: false,
    hasNumber: false,
    confirmPassword: false,
  };

  const [validRules, setValidRules] = useState(initialValidRules);

  useEffect(() => {
    setValidRules({
      ...validRules,
      minLength: minLengthRule(novaSenha),
      specialChar: specialCharacterRule(novaSenha),
      upperCase: upperCaseRule(novaSenha),
      lowerCase: lowerCaseRule(novaSenha),
      hasNumber: hasNumberRule(novaSenha),
      confirmPassword: confirmPasswordRule(novaSenha, confirmarSenha),
    });
  }, [novaSenha, confirmarSenha]);

  const enviarAlteracaoSenha = () => {
    UserService.resetPassword(resetId, novaSenha, confirmarSenha)
      .then(() => navigate("/acesso"))
      .catch((e) => console.log(`Error: ${e}`));
  };

  const toggleInputType = () => {
    setInputType((prevInputType) =>
      prevInputType === "password" ? "text" : "password"
    );
  };

  return (
    <div className="ativacao-container">
      <div className="ativacao-content">
        <Header />
        <div className="ativacao-section">
          <div className="ativacao-wrapper">
            <div className="ativacao-wrapper-header">
              <h1 className="ativacao-h1-header">Recuperar Senha</h1>
              <img
                src={hlAtivacao}
                alt="ativacao-img-header"
                className="ativacao-img-header"
              />
            </div>
            <div className="ativacao-form">
              <div className="ativacao-wrapper-label">
                <text className="ativacao-text-label">Nova Senha</text>
              </div>

              <div className="input-wrapper">
                <Input
                  value={novaSenha}
                  onChange={setNovaSenha}
                  placeholder="Nova Senha"
                  type={inputType}
                />

                <button
                  type="button"
                  className="input-password-with-label-toggle"
                  onClick={toggleInputType}
                >
                  {inputType === "password" ? (
                    <BsEye size={18} />
                  ) : (
                    <BsEyeSlash size={18} />
                  )}
                </button>
              </div>

              <div className="ativacao-wrapper-label">
                <text className="ativacao-text-label">Confirmar Senha</text>
              </div>
              <Input
                value={confirmarSenha}
                onChange={setConfirmarSenha}
                placeholder="Confirmar Senha"
                type={inputType}
              />

              {passwordRulesMessages.map((item) => (
                <div
                  key={JSON.stringify(item)}
                  className={
                    validRules && validRules[item.rule]
                      ? `ativacao-rules-label valido ${item.label}`
                      : "ativacao-rules-label"
                  }
                >
                  {item.message}
                </div>
              ))}

              <button
                className={
                  novaSenha.length > 0 && allValidValuesRules(validRules)
                    ? "ativacao-button-login"
                    : "ativacao-button-login disabled"
                }
                onClick={enviarAlteracaoSenha}
              >
                <img
                  src={icUser}
                  alt="ativacao-img-user"
                  className="ativacao-img-user"
                />
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
