import { ROUTES } from "../routing";

import { apiV1, apiV1WithToken } from "./api-v1";
import {
  getLocalRefreshToken,
  clearTokens,
  updateLocalAccessToken,
  updateLocalRefreshToken,
} from "./token";

const SIGNUP_PATH = "/users/signup";
const LOGIN_PATH = "/users/login";
const REFRESH_PATH = "/users/login/refresh";
const VERIFY_PATH = "/users/verify";

const signUp = async (nome, email, password, telefone) => {
  const response = await apiV1.post(SIGNUP_PATH, {
    name: nome,
    email,
    password,
    phone: telefone,
  });

  if (response.data.access_token) {
    updateLocalAccessToken(response.data.access_token);
  }
  if (response.data.refresh_token) {
    updateLocalRefreshToken(response.data.refresh_token);
  }

  return response.data;
};

const verify = async (signupId) => {
  try {
    const response = await apiV1.post(`${VERIFY_PATH}/${signupId}`);

    if (response.status === 200) {
      return { success: true };
    }

    return { success: false };
  } catch (e) {
    console.log(e);
    return { success: false, error: e };
  }
};

const login = async (email, password) => {
  const response = await apiV1.post(LOGIN_PATH, {
    email,
    password,
  });

  if (response.data.access_token) {
    updateLocalAccessToken(response.data.access_token);
  }
  if (response.data.refresh_token) {
    updateLocalRefreshToken(response.data.refresh_token);
  }
  return response.data;
};

export const logout = () => {
  clearTokens();
  window.location.href = ROUTES.login;
};

const refreshToken = async () => {
  try {
    const response = await apiV1WithToken.post(REFRESH_PATH, {
      refresh_token: getLocalRefreshToken(),
    });

    if (response.data.access_token) {
      updateLocalAccessToken(response.data.access_token);
    }
    if (response.data.refresh_token) {
      updateLocalRefreshToken(response.data.refresh_token);
    }
  } catch (error) {
    const data = error?.response?.data;
    if (data === "jwt expired" || data?.error === "jwt expired") {
      logout();
      return;
    }
  }
};

export const AuthService = {
  signUp,
  login,
  refreshToken,
  logout,
  verify,
};
