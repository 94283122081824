import PropTypes from "prop-types";
import "../styles/GoogleDriveEmbed.css";

const GoogleDriveEmbed = ({ width = 360, height = 638, embedId }) => (
  <div className="google-drive-embed">
    <iframe
      width={width}
      height={height}
      src={`https://drive.google.com/file/d/${embedId}/preview`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded Google Drive Video"
    />
  </div>
);

GoogleDriveEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default GoogleDriveEmbed;
