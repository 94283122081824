import fundoCaptacao from "../../../images/cursos-setores/fundoFinancas.png";
import imagemDireitaCaptacao from "../../../images/cursos-setores/imagemDireitaFinancas.png";
import card from "../../../images/card-fin.png";

export const SobreCursoCaptacao = () => {
  return (
    <>
      {" "}
      Esse curso é feito para que você conheça o funcionamento, principais
      atividades e desafios relacionados ao Setor de Captação de Recursos e
      Finanças. Isso será feito por meio de aulas expositivas, visitas de campo,
      discussões em grupo e estudos de caso. Não é necessário nenhum
      conhecimento prévio, apenas vontade de aprender e de contribuir. O curso
      será predominantemente presencial.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      A atividade coletiva de movimentos sociais de esquerda tem sido uma
      maneira de sustentar a luta do povo e ações que garantam a sobrevivência
      de enormes parcelas que foram abandonadas pelo Estado. A autossustentação
      e ações coletivas cumprem um papel na construção de laços de consciência
      entre aqueles que estão sendo oprimidos neste momento. É por isso que o
      MTST tem um setor responsável por organizar as finanças e buscar, de forma
      independente, fontes de financiamento.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const captacao = {
  id: "captacao",
  titulo: "Autossustentação da luta:",
  subtitulo: "principais ações e desafios",
  categoria: "Finanças",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo:
        "O Setor de Finanças e Captação de Recursos e os desafios da autossustentação do  MTST",
      formador: null,
    },
    {
      titulo: "Cozinha Solidária: sustentabilidade através da solidariedade",
      formador: null,
    },
    {
      titulo: "Caminhos para autossustentação do MTST – ocupações e loja",
      formador: null,
    },
    {
      titulo:
        "Caminhos para a autossustentação do MTST: captação por meio da inscrição de projetos em editais",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#B1C385",
    corTitulo: "#FEFCFB",
    corSubtitulo: "#010101",
    fundo: fundoCaptacao,
    itemDireita: imagemDireitaCaptacao,
  },
  materiais:
    "Como esse é um curso semipresencial, algumas vezes será necessário se deslocar até nossos territórios. Nestes dias, é importante levar garrafa d'água, kit sem-teto (copo, prato e talher), boné e capa de chuva. Também é interessante levar algum material para anotações, protetor solar e repelente.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Finanças",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VÍDEO+FINANÇAS.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511996682708",
};
