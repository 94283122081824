import { AulasConteudo } from "./AulasConteudo";
import { AulasFichamento } from "./AulasFichamento";
import { AulasMaterial } from "./AulasMaterial";
import { AulasVideo } from "./AulasVideo";

export const AulasPasso = ({ passo }) => {
  if (!passo) return null;

  if (passo.tipo === "video" || passo.tipo === "video-e-presencial") {
    return (
      <AulasVideo
        videoId={passo.videoId}
        locais={passo?.locais}
        tipo={passo?.tipo}
        finalizado={passo?.status === "finalizado"}
      />
    );
  }

  if (passo.tipo === "material") {
    return <AulasMaterial url={passo?.url} formato={passo.formato} />;
  }

  if (passo.tipo === "fichamento") {
    return (
      <AulasFichamento questoes={passo?.questoes} status={passo?.status} />
    );
  }

  return <AulasConteudo conteudo={passo?.conteudo} />;
};
