export const DadosSalvosModal = ({ isVisible, onDismiss }) => {
  return (
    <div
      className={
        isVisible ? "aulas-popup-container" : "aulas-popup-container-off"
      }
    >
      <div className="aulas-popup-wrapper">
        <text className="aulas-popup-text">DADOS SALVOS COM SUCESSO!</text>
        <button className="aulas-popup-button" onClick={onDismiss}>
          Voltar
        </button>
      </div>
    </div>
  );
};
