import Cleave from "cleave.js/react";

import "./input.css";

const getFieldErrors = (validationRules, value) => {
  return validationRules.map((rule) => rule(value)).filter((error) => !!error);
};

export const Input = ({
  label,
  value,
  onChange,
  validationRules = [],
  errorsVisible,
  placeholder,
  type = "text",
  readonly,
  maskOptions,
}) => {
  const errors = getFieldErrors(validationRules, value);
  const hasErrors = errors.length > 0;

  return (
    <>
      {label && <label>{label}</label>}
      {maskOptions ? (
        <Cleave
          options={maskOptions.options}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={readonly}
        />
      ) : (
        <input
          type={type}
          value={value}
          readOnly={readonly}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {hasErrors && errorsVisible && (
        <p className="input-msg-erro">{errors[0]}</p>
      )}
    </>
  );
};

export * from "./InputWithLabel";
