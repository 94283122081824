import { apiV1WithToken } from "./api-v1";

const getData = async () => {
  return (await apiV1WithToken.get("/users/me")).data;
};

const askNewPassword = async (email) => {
  return await apiV1WithToken.post("/users/recover", { email });
};

const resetPassword = async (resetId, password, confirmPassword) => {
  return await apiV1WithToken.post(`/users/reset/${resetId}`, {
    password,
    confirmPassword,
  });
};

export const UserService = { getData, resetPassword, askNewPassword };
