import fundoFormacao from "../../../images/cursos-setores/fundoFormacao.png";
import imagemDireitaFormacao from "../../../images/cursos-setores/imagemDireitaFormacao.png";
import card from "../../../images/card-for.png";

export const SobreCursoFormacao = () => {
  return (
    <>
      Menos que um curso e mais que uma apresentação, esses quatro encontros
      contam um pouco do nosso acúmulo metodológico dos últimos anos. A história
      de cada curso é inseparável da forma como o movimento faz a luta, seja
      pela escolha dos conteúdos, metodologia aplicada ou mesmo o local de
      execução. A luta é texto, subtexto, contexto e pretexto das nossas
      atividades. Trataremos de vivenciá-los junto com você, embaixo da lona, na
      porta dos barracos e, vez ou outra, nas salas de aula.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O MTST é mais que um movimento de moradia, é uma organização de
      trabalhadores que compreende a necessidade de uma profunda transformação.
      Atacar as causas da falta de moradia até eliminá-las. Nesse sentido, o
      papel do setor de formação é ligar a prática cotidiana aos seus aspectos
      mais gerais, denunciando as causas e, sobretudo, os causadores de tanto
      mal. Para isso, fazemos cursos de formação, podcasts, vídeos, materiais de
      agitação, grupos de estudo, voltados para a base, militância e apoiadores
      da luta do movimento.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const formacao = {
  id: "formacao",
  titulo: "Quem sabe mais, luta melhor:",
  subtitulo: "Apresentação do setor de formação do MTST",
  categoria: "Formação Política",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo:
        "Não somos os primeiros a chegar: Introdução ao Setor de Formação",
      formador: null,
    },
    {
      titulo:
        "Falar é fácil, difícil é ouvir: Como fazemos Formação de massa, coordenação e brigadas",
      formador: null,
    },
    {
      titulo:
        "Pela boca quase não se fala: como fazemos formação de militantes",
      formador: null,
    },
    {
      titulo: " No princípio só havia luta: Setor de Formação e seus desafios",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#AE2626",
    corTitulo: "#FEFCFB",
    corSubtitulo: "#010101",
    fundo: fundoFormacao,
    itemDireita: imagemDireitaFormacao,
  },
  materiais:
    "Como esse é um curso semipresencial, algumas vezes será necessário se deslocar até nossos territórios. Nestes dias, é importante levar itens básicos para passar o dia, como garrafa d'água, kit sem-teto (copo, prato e talher), boné, capa de chuva, protetor solar, repelente, materiais para as anotações e ânimo.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Formação Política",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VÍDEO+FORMAÇÃO.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511981369507",
};
