import RedeSocialFormulario from "../RedeSocialInput";

const FormInfoSocial = ({ redesSociais, setRedesSociais }) => {
  const botaoDesabilitado = redesSociais.length === 6 ? "desativo" : "";

  return (
    <div className="social-inputs-wrappper">
      {redesSociais.map((redeSocial) => (
        <RedeSocialFormulario
          key={redeSocial.id}
          redeSocial={redeSocial}
          redesSociais={redesSociais}
          setRedesSociais={setRedesSociais}
        />
      ))}
      <button
        className={`adicionar-rede-botao ${botaoDesabilitado}`}
        onClick={() => {
          if (botaoDesabilitado) {
            return;
          }
          setRedesSociais([
            ...redesSociais,
            {
              id: crypto.randomUUID(),
              tipoRedeSocial: "",
              nomeUsuario: "",
            },
          ]);
        }}
      >
        + Adicionar outra rede social
      </button>
    </div>
  );
};

export default FormInfoSocial;
