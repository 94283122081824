import React, { useCallback, useEffect, useState } from "react";
import { ConfirmacaoService } from "../services/ConfirmarService";
import { Header, FadeSpinner, ButtonZap } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../routing";
import "../styles/ConfirmarPresenca.css";

export const ConfirmarPresenca = () => {
  const navigate = useNavigate();
  const { idPasso } = useParams();
  const [loading, setLoading] = useState(true);
  const [feedback, setFeeback] = useState("");

  const solicitarConfirmacaoPresenca = useCallback(async () => {
    setLoading(true);
    const confirmacaResponse = await ConfirmacaoService.confirmarPresenca(
      idPasso
    );
    setLoading(false);

    if (confirmacaResponse.data.error) {
      setFeeback(confirmacaResponse.data.error);
    } else {
      setFeeback(confirmacaResponse.data.message);
    }
  }, []);

  useEffect(() => {
    solicitarConfirmacaoPresenca();
  }, [solicitarConfirmacaoPresenca]);

  return (
    <>
      <div className="confirmar-presenca-container">
        <div className="confirmar-presenca-content">
          <Header />

          {loading ? (
            <div className="confirmar-presenca-content-centered">
              <FadeSpinner color="#000" size={48} />
            </div>
          ) : (
            <section className="confirmar-presenca-section">
              <h1 className="confirmar-presenca-h1">{feedback}</h1>

              <h1 className="confirmar-presenca-feedback">
                Ainda não entrou na nossa comunidade? Entre agora para ficar por
                dentro das próximas aulas
              </h1>

              <ButtonZap
                link={"https://chat.whatsapp.com/DuBBpfkAjRb6YrDFMHgV2D"}
                message="Entrar na comunidade!"
                callback={() => navigate(ROUTES.areaAluno)}
              />
            </section>
          )}
        </div>
      </div>
    </>
  );
};
