// Importar Componentes:
import { useState } from "react";
import { Header, ButtonZap } from "../components";
import { generatePath, Link } from "react-router-dom";
import "../styles/Home.css";
import { ROUTES } from "../routing.js";

// Importar Imagens:
import icFechado from "../images/ic-perguntas-fechado.png";
import icAberto from "../images/ic-perguntas-aberto.png";
import icFone from "../images/ic-fone.png";
import icETB from "../images/ic-etb-large.png";
import icSpotify from "../images/ic-spotify.png";
import icTwitter from "../images/ic-twitter.png";
import icFace from "../images/ic-face.png";
import icInsta from "../images/ic-insta.png";
import icTwitch from "../images/ic-twitch.png";
import icYoutube from "../images/ic-youtube.png";

import fnP from "../images/fnP.jpg";
import rdL from "../images/rdL.webp";
import rlgbT from "../images/rlgbT.jpg";
import sdR from "../images/sdR.png";
import mtst from "../images/mtst.png";

import bgInicial from "../images/bg-inicial.webp";

import bgMadeirite from "../images/bg-madeirite.webp";
import bgTNT from "../images/bg-tnt.webp";

import cardConjuntura from "../images/card-conjuntura.png";
import cardHNL from "../images/card-hnl.png";
import cardPodocupa from "../images/card-podocupa.png";
import cardBarraco from "../images/card-barraco.png";
import cardCozinha from "../images/card-cozinha.png";
import cardLoja from "../images/card-loja.png";
import cardCurso from "../images/card-curso.png";
import cardMoradia from "../images/card-moradia.png";
import cardEstado from "../images/card-estado.png";
import cardPraCima from "../images/card-pra-cima.png";

export const Home = () => {
  return (
    <div className="home-container">
      <Header />

      <div className="home-content">
        <section className="one">
          <Section1 />
        </section>
        <section className="two">
          <Section2 />
        </section>
        <section className="three">
          <Section3 />
        </section>
        <section className="four">
          <Section4 />
        </section>
        <section className="five">
          <Section5 />
        </section>
        <section className="six">
          <Section6 />
        </section>
        <Parceiros />
        <RodapeLinks />
      </div>
    </div>
  );
};

const Section1 = () => {
  return (
    <div className="home-section1-container">
      <img
        src={bgInicial}
        alt="home-section1-img-inicial"
        className="home-section1-img-inicial"
      />
      <div className="home-section1-wrapper">
        <img
          src={icETB}
          alt="home-section1-img-logo"
          className="home-section1-img-logo"
        />
        <div className="home-section1-wrapper-text">
          <text className="home-section1-text-logo">
            Uma iniciativa{" "}
            <strong className="home-section1-text-bold"> popular </strong> para
            quem deseja construir a luta{" "}
            <strong className="home-section1-text-grifado">
              junto ao povo
            </strong>
          </text>
        </div>
      </div>
    </div>
  );
};

const Section2 = () => {
  const [mostrarBotao, setMostrarBotao] = useState(true);

  const classeBotao = mostrarBotao ? "desativado" : "";
  const classeImagem = mostrarBotao ? "" : "borrado";

  return (
    <div className="section2-container">
      <img src={bgMadeirite} alt="madeirite-bg" className="madeirite-bg-img" />
      <div className="section2-content">
        <h2 className="home-h2">NOSSOS CURSOS</h2>
        <div className="section2-cards-container">
          <img src={bgTNT} alt="tnt-bg" className="tnt-bg-img" />
          <div className="section2-cards">
            <div
              className="section2-card"
              onMouseEnter={() => setMostrarBotao(false)}
              onMouseLeave={() => setMostrarBotao(true)}
            >
              <img src={cardCurso} className={classeImagem} />
              <Link to={generatePath(ROUTES.course, { slugCurso: 1 })}>
                <button className={classeBotao}>Saiba mais</button>
              </Link>
            </div>
            <div className="section2-card">
              <img src={cardMoradia} />
            </div>
            <div className="section2-card">
              <img src={cardEstado} />
            </div>
            <div className="section2-card">
              <img src={cardPraCima} />
            </div>
          </div>
        </div>
      </div>
      <img src={icFone} alt="icone-fone" className="ic-fone" />
    </div>
  );
};

const Section3 = () => {
  const [mostrarBotao, setMostrarBotao] = useState(true);

  const classeBotao = mostrarBotao ? "desativado" : "";
  const classeImagem = mostrarBotao ? "" : "borrado";

  return (
    <div className="section3-container">
      <div className="section3-content">
        <h2 className="home-h2">QUEM SABE MAIS, LUTA MELHOR!</h2>
        <div className="section3-cards">
          <div
            className="section3-card"
            onMouseEnter={() => setMostrarBotao(false)}
            onMouseLeave={() => setMostrarBotao(true)}
          >
            <img
              src={cardHNL}
              alt="hoje-na-luta-card"
              className={classeImagem}
            />
            <Link to="/conteudos">
              <button className={classeBotao}>Saiba mais</button>
            </Link>
            <h3>
              Podcast com episódios diários e de rápido para resgatar e
              disseminar as lutas do povo.
            </h3>
          </div>
          <div className="section3-card">
            <img src={cardConjuntura} alt="conjuntura-card" />
            <h3>
              Análises rápidas e didáticas do cenário político brasileiro e
              internacional pela visão e interesses dos trabalhadores.
            </h3>
          </div>
          <div className="section3-card">
            <img src={cardPodocupa} alt="podocupa-card" />
            <h3>Podcast sobre a luta dos trabalhadores na atualidade.</h3>
          </div>
          <div className="section3-card">
            <img src={cardBarraco} alt="barraco-card" />
            <h3>
              Apresentação e discussão de teses acadêmicas sobre as lutas
              populares
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section4 = () => {
  return (
    <div className="section4-container" id="quem-somos">
      <div className="section4-content">
        <h2 className="home-h2">MAS AFINAL, QUEM SOMOS NÓS?</h2>
        <h3>
          A Escola de Trabalho de Base é um centro de formação impulsionado pelo
          MTST e um conjunto de parceiros. Nosso principal objetivo é fortalecer
          quem acredita em um país melhor, sem fascistas e com o povo no poder.
          <br />
          <br />
          Ela vem sendo desenhada para atender a demanda de quem deseja lutar
          mas sente que precisa de ajuda, algum lugar para conhecer outras
          experiências de lutas e iniciativas comunitárias.
          <br />
          <br />
          Não necessariamente às grandes revoluções, elas são importantes e
          fundamentais, mas também as cotidianas, batalhas pela melhora do
          bairro, pelo atendimento no posto de saúde, pelas vagas na creche e é
          claro, ocupações de terra.
          <br />
          <br />
          Em resumo, somos o espaço de quem quer saber mais para lutar melhor.
        </h3>
      </div>
    </div>
  );
};

const Section5 = () => {
  const [mostrarBotao1, setMostrarBotao1] = useState(true);
  const [mostrarBotao2, setMostrarBotao2] = useState(true);

  const classeBotao1 = mostrarBotao1 ? "desativado" : "";
  const classeImagem1 = mostrarBotao1 ? "" : "borrado";

  const classeBotao2 = mostrarBotao2 ? "desativado" : "";
  const classeImagem2 = mostrarBotao2 ? "" : "borrado";

  return (
    <div className="section5-container" id="apoie">
      <div className="section5-content">
        <h2 className="home-h2">APOIE!</h2>
        <div className="section5-cards">
          <div
            className="section5-card"
            onMouseEnter={() => setMostrarBotao1(false)}
            onMouseLeave={() => setMostrarBotao1(true)}
          >
            <img
              src={cardCozinha}
              alt="cozinha-card"
              className={classeImagem1}
            />
            <a
              href="https://apoia.se/cozinhasolidaria"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={classeBotao1}>Saiba mais</button>
            </a>
          </div>
          <div
            className="section5-card"
            onMouseEnter={() => setMostrarBotao2(false)}
            onMouseLeave={() => setMostrarBotao2(true)}
          >
            <img src={cardLoja} alt="loja-card" className={classeImagem2} />
            <a
              href="https://wa.me/5511914631714"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={classeBotao2}>Saiba mais</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const QandA = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState();
  const questionClassName = isExpanded
    ? "question question-expanded"
    : "question";
  const icon = isExpanded ? icAberto : icFechado;

  return (
    <div className="qa">
      <div onClick={() => setIsExpanded(!isExpanded)}>
        <h3 className={questionClassName}>
          <img src={icon} alt="setinha" className="icon-perguntas" />
          {question}
        </h3>
      </div>
      {isExpanded && <h3 className="answer">{answer}</h3>}
    </div>
  );
};

const Section6 = () => {
  const Q_AND_AS = [
    {
      question: "O que é o MTST?",
      answer:
        "É um movimento popular, urbano e de esquerda que organiza trabalhadores pela moradia.",
    },
    {
      question: "O que é a Escola de Trabalho de Base",
      answer:
        "Um centro de formação impulsionado pelo MTST para constribuir com o avanço e qualificação do trabalho de base no Brasil.",
    },
    {
      question: "Os cursos são pagos?",
      answer: "Não, nenhum dos nossos cursos é pago.",
    },
    {
      question: "Por quanto tempo tenho acesso ao conteúdo dos cursos?",
      answer:
        "Nossos cursos normalmente não são gravados. Porém, todo material de apoio e revisão fica disponível na área do aluno.",
    },
    {
      question: "Todos os cursos possuem atividades presenciais?",
      answer:
        "Não, isso varia de acordo com a especificidade de cada curso. Porém, fazemos trabalho de base: pé-no-barro e mão-na-massa são essenciais para essa construção.",
    },
    {
      question: "Tem certificado?",
      answer: "Sim, todos os cursos possuem certificado para quem os concluir.",
    },
  ];

  return (
    <div className="section6-container" id="faq">
      <h2 className="home-h2">DÚVIDAS FREQUENTES</h2>
      <div className="section6-content">
        <div className="section6-perguntas">
          {Q_AND_AS.map(({ question, answer }) => (
            <QandA key={question} question={question} answer={answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Parceiros = () => {
  return (
    <div className="rodape-links">
      <div className="links">
        <span>Parceiros:</span>
        <a
          href="https://www.instagram.com/juventudefogonopavio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={fnP} className="ic-link" alt="" />
        </a>
        <a
          href="https://www.instagram.com/trabalhadoressemdireitos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={sdR} className="ic-link" alt="" />
        </a>
        <a
          href="https://www.instagram.com/revolucao.lgbt/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={rlgbT} className="ic-link" alt="" />
        </a>
        <a
          href="https://www.instagram.com/mtstbrasil/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mtst} className="ic-link" alt="" />
        </a>
        <a
          href="https://www.instagram.com/movraizdaliberdade/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={rdL} className="ic-link" alt="" />
        </a>
      </div>
    </div>
  );
};

const RodapeLinks = () => {
  return (
    <div className="rodape-links">
      <div className="links">
        <span>Por aí:</span>
        <a
          href="https://instagram.com/trabalhodebase.escola"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={icInsta}
            className="ic-link"
            alt="Instagram Escola Trabalho de Base"
          />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100086261184985"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={icFace}
            className="ic-link"
            alt="Facebook Escola Trabalho de Base"
          />
        </a>
        <a
          href="https://twitter.com/trabalhodebase"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={icTwitter}
            className="ic-link"
            alt="Twitter Escola Trabalho de Base"
          />
        </a>
        <a
          href="https://www.youtube.com/@trabalhodebase"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={icYoutube}
            className="ic-link"
            alt="Youtube Escola Trabalho de Base"
          />
        </a>
        <a
          href="https://open.spotify.com/user/rwcnogyx1o5z7yaa99cmkcdfr?si=01b3729c21934694"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={icSpotify}
            className="ic-link"
            alt="Spotify Escola Trabalho de Base"
          />
        </a>
        <a
          href="https://www.twitch.tv/trabalhodebase_mtst"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={icTwitch}
            className="ic-link"
            alt="Twitch Escola Trabalho de Base"
          />
        </a>
      </div>
      <ButtonZap link={"https://wa.me/5511949322544"} />
    </div>
  );
};
