import "../styles/MinhaAgenda.css";

// Importar Imagens:
import icBone from "../images/ic-bone.png";
import icRemoto from "../images/ic-remoto.png";

export const MinhaAgenda = () => {
  const minhaAgenda = [
    {
      id: 1,
      data: "2023-10-12T08:00:00Z",
      datastring1: "02/01",
      titulo: "Módulo 2 | Inscrição",
      subtitulo: "Abertura da Inscrição dos Cursos Setoriais",
      icone: null,
    },
    {
      id: 2,
      data: "2023-10-12T08:00:00Z",
      datastring1: "07/01",
      titulo: "Encontro de Abertura - Presencial*",
      subtitulo:
        "* Encontro sujeito a organização do MTST no seu Estado: DF, PE e PR confirmados Fique atento(a) à Comunidade do whatsapp.",
      icone: null,
    },
    {
      id: 3,
      data: "2023-10-12T08:00:00Z",
      datastring1: "10/01",
      titulo: "Módulo 2 | Inscrição",
      subtitulo: "Fechamento das inscrições",
      icone: null,
    },
    {
      id: 4,
      data: "2023-10-12T08:00:00Z",
      datastring1: "14/01",
      titulo: "Módulo 2 | Aula 1",
      subtitulo: "Início dos cursos setoriais",
      icone: null,
    },
  ];
  const agendaList = minhaAgenda.map((agenda, index) => (
    <div key={index} className="agenda-wrapper-evento">
      <div className="agenda-wrapper-icone">
        <img
          src={agenda.icone}
          className={
            agenda.icone !== null
              ? "agenda-wrapper-img-icone"
              : "agenda-wrapper-img-icone-off"
          }
          alt="agenda-wrapper-img-icone"
        />
        <div className="agenda-wrapper-data">
          {agenda.datastring1}
          <br />
          {agenda.datastring2}
        </div>
      </div>
      <text className="agenda-text-evento">
        <b className="agenda-b-evento">{agenda.titulo}</b>
        <br />
        {agenda.subtitulo}
      </text>
    </div>
  ));

  return <>{agendaList}</>;
};
