import fundoJuridico from "../../../images/cursos-setores/fundoJuridico.png";
import imagemDireitaJuridico from "../../../images/cursos-setores/imagemDireitaJuridico.png";
import card from "../../../images/card-jur.png";

export const SobreCursoJuridico = () => {
  return (
    <>
      Esse curso procura responder como o direito pode servir à luta do povo e
      apresentar o Setor Jurídico do MTST como uma proposta prática de um fazer
      jurídico que de fato seja instrumento da luta popular.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O Setor jurídico reúne juristas comprometidos com uma prática
      contra-hegemônica e orientada pela luta social. Sem romantizar o Direito
      ou a atuação, membros vindos das mais diversas especialidades acreditam e
      constroem um uso tático e inovador da Lei, aliado e orientado pela luta
      popular. Se a estratégia do MTST é a realização das transformações por
      meio da construção do poder popular, o Setor Jurídico se orienta por ela e
      constrói um direito insurgente - uma prática jurídica voltada ao
      fortalecimento das lutas do nosso povo.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const juridico = {
  id: "juridico",
  titulo: "Um direito que serve à luta do povo",
  categoria: "Jurídico",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "O Direito pode servir à luta popular?",
      formador: null,
    },
    {
      titulo: "Militantes ou juristas? Descobrindo o setor jurídico",
      formador: null,
    },
    {
      titulo:
        "Quando a Lei não basta: discutindo a realidade do Direito e a realidade do povo",
      formador: null,
    },
    {
      titulo:
        "O Direito em movimento - vivência prática das atividades do Setor Jurídico",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#101010",
    corTitulo: "#FEFCFB",
    fundo: fundoJuridico,
    itemDireita: imagemDireitaJuridico,
  },
  materiais:
    "A vontade de encarar o sistema de frente é essencial. Além disso, o curso será semipresencial, com a maior parte das atividades online. Logo, é necessária uma conexão com internet e câmera/microfone habilitados. Para atividades presenciais, é necessário levar kit sem-teto, água, calçado e roupas confortáveis (pensem em bonés, chapéus e guarda-chuvas). É recomendável levar capa de chuva, repelente e protetor solar.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Jurídico",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/VI%CC%81DEO+JURI%CC%81DICO.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511969117772",
};
