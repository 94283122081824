import { Link } from "react-router-dom";
import { ROUTES } from "../../../../routing";
import icFotoUsarioPadrao from "../../../../images/ic-user2.png";
import icVoltar from "../../../../images/ic-voltar.png";

const FormHeader = ({ nomeAlunoAtual }) => {
  return (
    <div className="formulario-header">
      <div className="formulario-perfil">
        <div className="formulario-foto-perfil-container">
          <img
            className="formulario-foto-perfil"
            src={icFotoUsarioPadrao}
            alt="foto do perfil"
          />
        </div>
        <text className="nome-perfil">{nomeAlunoAtual}</text>
      </div>
      <Link className="voltar-botao" to={ROUTES.areaAluno}>
        <text className="voltar-botao-texto">Voltar</text>
        <img className="voltar-botao-icone" src={icVoltar} alt="icone voltar" />
      </Link>
    </div>
  );
};

export default FormHeader;
