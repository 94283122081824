import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { SignupIdContext } from "./contexts";
import { ROUTES } from "./routing";
import { NaoEncontrado } from "./pages/NaoEncontrado";
import { AtivacaoCadastro } from "./pages/AtivacaoCadastro";
import { ConfirmarPresenca } from "./pages/ConfirmarPresenca";
import { Acesso } from "./pages/Acesso";
import Perfil from "./pages/Perfil";
import { Curso } from "./pages/Curso";
import { Aulas } from "./pages/Aulas";
import { AreaAluno } from "./pages/AreaAluno";
import { Conteudos } from "./pages/Conteudos";
import { Home } from "./pages/Home";
import { AlterarSenha } from "./pages/AlterarSenha";
import { RecuperarSenha } from "./pages/RecuperarSenha";
import { EsqueciASenha } from "./pages/EsqueciASenha";
import setupInterceptors from "./services/setupInterceptors";
import Protected from "./services/Protected.js";
import ScrollTop from "./services/ScrollTop.js";
import store from "./store/store";
import "./App.css";

//Fontes Externas:
import "./fonts/galano-grotesque-bold.otf";
import "./fonts/galano-grotesque-medium.otf";
import "./fonts/kg-life-is-messy.ttf";

function App() {
  const [signupId, setSignupId] = useState();

  const queryClient = new QueryClient();

  useEffect(() => {
    const localSignupKey = localStorage.getItem("signup-id");
    if (signupId && signupId !== "undefined" && !localSignupKey) {
      localStorage.setItem("signup-id", signupId);
    } else if (!signupId && localSignupKey && localSignupKey !== "undefined") {
      setSignupId(localSignupKey);
    }
  }, [signupId]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SignupIdContext.Provider value={[signupId, setSignupId]}>
          <Router>
            <ScrollTop>
              <Routes>
                {/* ROTAS DESLOGADAS */}
                <Route element={<Home />} path={ROUTES.home} exact />
                <Route element={<Acesso />} path={ROUTES.login} />
                <Route element={<Curso />} path={ROUTES.course} />
                <Route element={<Conteudos />} path={ROUTES.contents} />
                <Route
                  element={<AtivacaoCadastro />}
                  path={ROUTES.activation}
                />
                <Route
                  element={<EsqueciASenha />}
                  path={ROUTES.esqueciASenha}
                />
                <Route
                  element={<RecuperarSenha />}
                  path={ROUTES.recuperarSenha}
                />
                <Route element={<NaoEncontrado />} path="*" />
                {/* ROTAS PROTEGIDAS POR LOGIN */}
                <Route element={<Protected />}>
                  <Route element={<AreaAluno />} path={ROUTES.areaAluno} />
                  <Route element={<AreaAluno />} path={ROUTES.areaAluno2} />
                  <Route element={<Aulas />} path={ROUTES.classes} />
                  <Route
                    element={<AlterarSenha />}
                    path={ROUTES.updatePassword}
                  />
                  <Route element={<Perfil />} path={ROUTES.profile} />
                  <Route
                    element={<ConfirmarPresenca />}
                    path={ROUTES.confirmarPresenca}
                  />
                </Route>
              </Routes>
              <footer>
                <span className="texto-footer">
                  Plataforma construída com trabalho militante
                </span>
              </footer>
            </ScrollTop>
          </Router>
        </SignupIdContext.Provider>
      </Provider>
    </QueryClientProvider>
  );
}

setupInterceptors(store);

export default App;
