import fundoSaude from "../../../images/cursos-setores/fundoSaude.png";
import imagemDireitaSaude from "../../../images/cursos-setores/imagemDireitaSaude.png";
import card from "../../../images/card-sau.png";

export const SobreCursoSaude = () => {
  return (
    <>
      Este curso é feito para que você conheça um dos setores do MTST a partir
      de quem constrói a Saúde com o pé no barro. No decorrer dos encontros, os
      participantes terão a oportunidade de ter contato com os desafios,
      limitações, possibilidades e dilemas da Saúde Popular. Considerando a
      Saúde em seu âmbito político, ecológico, biopsicossocial e espiritual,
      buscamos abranger neste curso um olhar amplo sobre os elementos de uma
      saúde periférica em sua essência.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O setor atua politicamente na prevenção, promoção e educação em Saúde, a
      partir dos territórios onde o MTST realiza o trabalho de base.
      <br />
      <br />
      Este coletivo tem como compromisso defender a saúde pública e gratuita,
      bem como o acesso ao direito às políticas sociais em todas as esferas
      governamentais. Além disso, atuamos junto ao povo nas ocupações e
      comunidades, com atendimentos, mutirões de saúde e assistência social.
      Buscamos atuar de forma multidisciplinar, garantindo que o direito à saúde
      seja capilarizado.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const saude = {
  id: "saude",
  titulo: "Com o povo e para o povo:",
  subtitulo: "construindo a saúde sem-teto",
  categoria: "Saúde",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Dos desafios às potências: por que um setor de saúde sem teto?",
      formador: null,
    },
    {
      titulo: "Saúde: a capacidade de lutar e resistir!",
      formador: null,
    },
    {
      titulo:
        "Saúde mental e capitalismo: adoecimento, resistência e transformação",
      formador: null,
    },
    {
      titulo: "Saúde se faz na prática: do pé-no-barro ao pertencimento",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#72B7CD",
    corTitulo: "#FEFCFB",
    corSubtitulo: "#101010",
    fundo: fundoSaude,
    itemDireita: imagemDireitaSaude,
  },
  materiais:
    "Para fluir de forma dinâmica, teremos atividades de maneira remota e também presencial. Esperamos vocês nesta jornada de reflexão da luta pela democratização da saúde em todos os seus aspectos! Deste modo, leve com você o kit sem-teto que sempre utilizamos nas atividades presenciais nos territórios.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Saúde",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/V%C3%8DDEO+SA%C3%9ADE.mp4",
      orientacao: "horizontal",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511942123393",
};
