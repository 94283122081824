import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { ESTADOS } from "./estados.js";

import "./matricula.css";

const OPCOES_HORARIO = [
  { label: "Terça-feira, 20h", value: "ter" },
  { label: "Quarta-feira, 20h", value: "qua" },
  { label: "Quinta-feira, 20h", value: "qui" },
];

const REGIOES_SP = [
  {
    label: "Região Metropolitana de São Paulo (Presencial)",
    value: "Região Metropolitana de São Paulo",
  },
  {
    label: "Região Metropolitana de Campinas",
    value: "Região Metropolitana de Campinas",
  },
  { label: "Interior de São Paulo", value: "Interior de São Paulo" },
  { label: "Litoral Paulista", value: "Litoral Paulista" },
];

const getTemaETB = (tema) => ({
  ...tema,
  borderRadius: "15px",
});

const OPCOES_ESTADO = ESTADOS.map((estado) => ({
  label: estado.nome,
  value: estado.sigla,
}));

const getOpcoesCidadesDoEstadoPelaSigla = (sigla) => {
  if (!sigla) return [];
  if (sigla === "SP") return REGIOES_SP;
  const estado = ESTADOS.find((e) => e.sigla === sigla);
  return estado.cidades.map((cidade) => ({ label: cidade, value: cidade }));
};

export const InfoExtraMatriculaForm = ({ matricular }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    getValues,
    watch,
  } = useForm();

  const primeiroHorario = watch("disponibilidadeHorarioDiscussao");
  const estado = watch("estado");

  return (
    <>
      <h2 className="informacao-extra-header">Falta pouco!</h2>
      <p className="informacao-extra-p">
        Para esse curso, precisamos de mais algumas informações
      </p>
      <form
        onSubmit={handleSubmit((formData) =>
          matricular({
            ...formData,
            cidade: formData.cidade.value,
            estado: formData.estado.value,
            disponibilidadeHorarioDiscussao:
              formData.disponibilidadeHorarioDiscussao.value,
            disponibilidadeHorarioDiscussao02:
              formData.disponibilidadeHorarioDiscussao02.value,
          })
        )}
        className="informacao-extra-form"
      >
        <label className="informacao-extra-label">Qual a sua profissão?</label>
        <input
          {...register("profissao", { required: "Profissão é obrigatória" })}
        />
        {errors.profissao && (
          <small className="input-error">* {errors.profissao.message}</small>
        )}

        <label className="informacao-extra-label">
          Por que deseja se inscrever no curso?
        </label>
        <input
          {...register("motivacao", { required: "Motivo é obrigatório" })}
        />
        {errors.motivacao && (
          <small className="input-error">* {errors.motivacao.message}</small>
        )}
        <label className="informacao-extra-label">
          Participa ou participou de alguma organização política, movimento
          social, partido ou sindicato?
        </label>
        <input
          {...register("participacaoPolitica", {
            required: "Pergunta obrigatória",
          })}
        />
        {errors.participacaoPolitica && (
          <small className="input-error">
            * {errors.participacaoPolitica.message}
          </small>
        )}

        <label className="informacao-extra-label">Local de Residência</label>
        <div className="row-wrapper">
          <div className="dropdown-wrapper">
            <Controller
              control={control}
              name="estado"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={"Estado"}
                  options={OPCOES_ESTADO}
                  theme={getTemaETB}
                />
              )}
              rules={{ required: "Obrigatório" }}
            />
            {errors.estado && (
              <small className="input-error">* {errors.estado.message}</small>
            )}
          </div>
          <div className="dropdown-wrapper">
            <Controller
              control={control}
              name="cidade"
              render={({ field }) => (
                <Select
                  {...field}
                  isDisabled={!estado}
                  placeholder={estado?.value === "SP" ? "Região" : null}
                  options={getOpcoesCidadesDoEstadoPelaSigla(estado?.value)}
                  theme={getTemaETB}
                />
              )}
              rules={{ required: "Obrigatório" }}
            />
            {errors.cidade && (
              <small className="input-error">* {errors.cidade.message}</small>
            )}
          </div>
        </div>

        <span className="mensagem-atencao">
          📍O curso na Região metropolitana de SP é presencial
        </span>
        <label className="informacao-extra-label">
          Teremos reuniões semanais para discussões em grupo. Escolha as
          melhores opções para você.
        </label>
        <div className="row-wrapper">
          <div className="dropdown-wrapper">
            <Controller
              control={control}
              name="disponibilidadeHorarioDiscussao"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={"Primeira Opção"}
                  options={OPCOES_HORARIO}
                  theme={getTemaETB}
                />
              )}
              rules={{ required: "Obrigatório" }}
            />
            {errors.disponibilidadeHorarioDiscussao && (
              <small className="input-error">
                * {errors.disponibilidadeHorarioDiscussao.message}
              </small>
            )}
          </div>
          <div className="dropdown-wrapper">
            <Controller
              control={control}
              name="disponibilidadeHorarioDiscussao02"
              render={({ field }) => (
                <Select
                  {...field}
                  isDisabled={!primeiroHorario}
                  placeholder={"Segunda Opção"}
                  options={OPCOES_HORARIO.filter(
                    (horario) => horario !== primeiroHorario
                  )}
                  theme={getTemaETB}
                />
              )}
              rules={{
                required: "Obrigatório",
                validate: (value) =>
                  value !== getValues("disponibilidadeHorarioDiscussao") ||
                  "Os horários devem ser diferentes",
              }}
            />
            {errors.disponibilidadeHorarioDiscussao02 && (
              <small className="input-error">
                * {errors.disponibilidadeHorarioDiscussao02.message}
              </small>
            )}
          </div>
        </div>

        <button type="submit" className="acesso-button acesso-button-primary">
          Inscrever-se
        </button>
      </form>
    </>
  );
};
