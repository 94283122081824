import fundoAnimais from "../../../images/cursos-setores/fundoAnimais.png";
import imagemDireitaAnimais from "../../../images/cursos-setores/imagemDireitaAnimais.png";
import card from "../../../images/card-animais.png";

export const SobreCursoAnimais = () => {
  return (
    <>
      Nesse curso, vamos compartilhar um pouco das experiências do Setor Animais
      Sem-Teto. Esperamos, assim, poder debater sobre nossa atuação política na
      promoção e conscientização de políticas sociais e educação em saúde,
      cuidado e bem-estar dos animais nos territórios onde o MTST realiza suas
      atividades de base.
    </>
  );
};

const TextoSobreSetor = () => {
  return (
    <>
      O setor surge da preocupação dedicada aos animais residentes nas ocupações
      do MTST, que podem ser tanto tutorados quanto identificados como animais
      comunitários, provenientes do abandono na vizinhança. Nosso objetivo
      principal é contribuir para o convívio harmonioso e a melhoria das
      condições sanitárias nos territórios onde atuamos.
    </>
  );
};

// TODO(Oda): Precisamos pegar as aulas do estruturaAulas e depois passar tudo pro backend
export const animais = {
  id: "animais",
  titulo: "Lutar junto ao povo com os animais sem-teto",
  categoria: "Animais Sem-teto",
  data_inicio: "2024-01-14T12:00:00.000Z",
  formato: "Presencial e online*",
  card,
  aulas: [
    {
      titulo: "Por que existe um setor de Animais no MTST? ",
      formador: null,
    },
    {
      titulo: "A Luta para além da doação de animais",
      formador: null,
    },
    {
      titulo: "Cuidados para os Cães e Gatos, junto ao poder público",
      formador: null,
    },
    {
      titulo: "Construindo o futuro através da consciência popular",
      formador: null,
    },
  ],
  inscritos: 0,
  banner: {
    cor: "#D72B4E",
    corTitulo: "#FEFCFB",
    fundo: fundoAnimais,
    itemDireita: imagemDireitaAnimais,
  },
  materiais: "Material para anotações.",
  formadores: null,
  setorOuColetivo: {
    tipo: "setor",
    nome: "Animais Sem-teto",
    video: {
      referencia:
        "https://nt-mtst-etb.s3.us-east-2.amazonaws.com/video-animais.mp4",
      orientacao: "vertical",
    },
    Texto: TextoSobreSetor,
  },
  linkZap: "https://api.whatsapp.com/send/?phone=5511988430747",
};
