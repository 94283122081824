// Importar Componentes:
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../../routing";
import { logout } from "../../../store/actions/auth";
import { SidebarAula } from "./SidebarAula";

// Importar Imagens:
import icETB from "../../../images/ic-etb1.png";
import icUser from "../../../images/ic-user2.png";
import icMobile from "../../../images/ic-mobile.png";

import "../../../components/Header/Header.css";

export const HeaderAula = ({ titulo }) => {
  const [sidebar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.reload(false);
    navigate(ROUTES.home);
  };

  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="header-wrapper-logo">
          <Link to={ROUTES.home}>
            <img
              src={icETB}
              alt="header-img-home"
              className="header-img-home"
            />
          </Link>
        </div>
        <div className="header-wrapper-links">
          <span className="header-text-titulo">{titulo}</span>
        </div>
        {/* ÁREA DE LOGIN */}
        <div className="header-wrapper-login">
          <Link className="header-link-static" onClick={handleLogout}>
            Sair
          </Link>
          <img src={icUser} alt="header-img-user" className="header-img-user" />
        </div>
        <div className="header-wrapper-mobile">
          <img
            src={icMobile}
            alt="header-img-mobile"
            className="header-img-mobile"
            onClick={showSidebar}
          />
          {sidebar && <SidebarAula active={setSidebar} />}
          <div className="header-dropdown-mobile-ativo"></div>
        </div>
      </div>
    </div>
  );
};
