import { useState } from "react";
import GoogleDriveEmbed from "../../services/GoogleDriveEmbed.js";
import ReactPlayer from "react-player";

import hlCurso1 from "../../images/hl-rabisco3.png";

export const SobreCurso = ({ TextoSobreCurso }) => {
  return (
    <div className="curso-secao-informacao">
      <h1 className="curso-secao-informacao-titulo">SOBRE O CURSO</h1>
      <img
        src={hlCurso1}
        alt="curso-section2-img-rabisco1"
        className="curso-section2-img-rabisco1"
      />
      <div className="curso-secao-informacao-conteudo">
        {TextoSobreCurso ? (
          <TextoSobreCurso />
        ) : (
          <>
            Ao longo dos anos o MTST acumulou muitas experiências sobre trabalho
            de base. Por meio desse curso você pode ter contato com algumas
            delas. O curso começa com uma introdução mais geral e vai, aos
            poucos, aprofundando a vivência no cotidiano do movimento.
            <br />
            <br />
            Para quem é de São Paulo, o curso é presencial com momentos online.
            Para todos os outros locais o curso é a principio online.
          </>
        )}
      </div>
    </div>
  );
};

export const SobreSetorOuColetivo = ({ setorOuColetivo }) => {
  const { tipo, video, Texto } = setorOuColetivo;
  const { referencia, orientacao } = video;

  return (
    <div className="curso-secao-informacao">
      <div className="curso-secao-informacao-titulo-wrapper">
        <h1 className="curso-secao-informacao-titulo">
          CONHEÇA O {tipo.toUpperCase()}
        </h1>
      </div>
      <div className="curso-secao-informacao-conteudo">
        <div
          className={
            orientacao === "vertical"
              ? "curso-secao-informacao-video-vertical"
              : "curso-secao-informacao-video-horizontal"
          }
        >
          {referencia ? (
            <VideoComponent referencia={referencia} orientacao={orientacao} />
          ) : null}
          <div className="curso-sobre-setor-ou-coletivo">
            <Texto />
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoComponent = ({ orientacao, referencia }) => {
  const [hasError, setHasError] = useState(false);
  const [erroMsg, setErroMsg] = useState("");

  if (!referencia) return null;

  if (referencia.includes("https://nt-mtst")) {
    return (
      <div
        style={{
          aspectRatio: !hasError
            ? orientacao === "vertical"
              ? 325 / 574
              : 4 / 3
            : 0,
          minWidth: 250,
          maxWidth: orientacao === "vertical" ? 325 : 574,
        }}
      >
        {!hasError && (
          <ReactPlayer
            url={referencia}
            controls={true}
            width={"100%"}
            height={"100%"}
            onError={(erro) => {
              setErroMsg(erro?.message || erro?.cod || erro);
              setHasError(true);
            }}
          />
        )}

        {hasError && (
          <div className="curso-video-erro-wrapper">
            <p className="curso-video-erro-text">
              Ocorreu um erro ao reproduzir o video
            </p>
            <p className="curso-video-erro-erro">{erroMsg}</p>
          </div>
        )}
      </div>
    );
  }
  return <GoogleDriveEmbed embedId={referencia} />;
};
