import { useQuery, useMutation } from "@tanstack/react-query";

import { apiV1WithToken } from "../services/api-v1";

const salvarInformacoes = async (informacoes) => {
  return await apiV1WithToken.post(
    "/informacoes-curso-brigadas/registrar",
    informacoes
  );
};

const buscaInformacoes = async () => {
  return (await apiV1WithToken.get("/informacoes-curso-brigadas/verificar"))
    .data;
};

export const useSalvarInformacoesBrigadas = () => {
  return useMutation({
    mutationFn: salvarInformacoes,
  });
};

export const useBuscaInformacoesBrigadas = () => {
  return useQuery({
    queryKey: ["informacoes-brigadas"],
    queryFn: buscaInformacoes,
    enabled: false,
  });
};
