const ScriptGoogleSource = "https://accounts.google.com/gsi/client";

class GoogleAuth {
  tokenClient = null;

  constructor() {
    this.loadClient();
  }

  loadClient() {
    const scriptGoogle = document.createElement("script");
    scriptGoogle.src = ScriptGoogleSource;
    scriptGoogle.async = true;
    scriptGoogle.defer = true;
    document.body.appendChild(scriptGoogle);
    scriptGoogle.onload = async () => {
      // eslint-disable-next-line no-undef
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/calendar",
        prompt: "",
        callback: () => {},
      });
    };
  }

  async askPermission() {
    return new Promise((resolve, reject) => {
      try {
        this.tokenClient.callback = (resp) => {
          resolve(resp);
        };

        this.tokenClient.error_callback = (resp) => {
          reject(resp);
        };

        this.tokenClient.requestAccessToken({
          prompt: "consent",
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  setGoogleCalendarToken(token) {
    if (typeof window !== "undefined") {
      localStorage.setItem("googleCalendarToken", token);
    } else {
      return null;
    }
  }

  getGoogleCalendarToken() {
    if (typeof window !== "undefined") {
      return localStorage.getItem("googleCalendarToken");
    } else {
      return null;
    }
  }

  setGoogleCalendarTokenExpiration(dataExpiracao) {
    if (typeof window !== "undefined") {
      localStorage.setItem("googleCalendarTokenExpiration", dataExpiracao);
    } else {
      return null;
    }
  }

  getGoogleCalendarTokenExpiration() {
    if (typeof window !== "undefined") {
      return localStorage.getItem("googleCalendarTokenExpiration");
    } else {
      return null;
    }
  }
}

export { GoogleAuth };
